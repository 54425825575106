@import '../../styles/includes.scss';

.share {
  border-top: 1px solid rgba(112, 112, 112, .2);
  padding-top: 15px;
  padding-bottom: 20px;
    .value {
      font: normal normal normal 14px/28px Open Sans;
      letter-spacing: -0.28px;
      color: $black;
    }
    a {
      padding: 0 7.5px;
    }
    button {
      padding: 0 7.5px;
      display: none;

      @include screen(md) {
        display: inline-block;
      }
    }
    i {
      color: #585858;
      font-size: 21px;
      width: 21px;
      height: 21px;
      line-height: 21px;
    }
  }