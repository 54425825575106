.select {
  margin-top: 5px;
  box-shadow: none !important;
  border-radius: 4px;
  outline: 1px solid transparent;
  transition: 1s;
}


.disabled {
  opacity: 0.9;
}