.wrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
  cursor: pointer;
}

.container {
  width: 19px;
  height: 19px;
  background: #62a3bb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}

.dec,
.dec2 {
  width: 30%;
  height: 1px;
  background: #fff;
  position: absolute;
}

.dec2 {
  transform: translateY(-1px);
}

.dec {
  transform: translateY(1px);
}

.dec::before,
.dec::after {
  content: '';
  width: 140%;
  height: 1px;
  background: #fff;
  position: absolute;
  margin-left: -20%;
  transition: 1s;
}

.dec::before {
  transform: translateY(-5px);
}

.dec::after {
  transform: translateY(3px);
}


.wrap:hover .dec::before,
.wrap:hover .dec::after {
  opacity: 0;
}