.wrap {
  display: grid;
  column-gap: 10px;
  grid-template-columns: 30px auto;
  align-items: flex-end;
  position: relative;
}

.indicator {
  background: #B2C026;
  height: 51px;
  transition: 1s;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator i {
  font-size: 14px;
  color: #fff;
}

.indicatorActive {
  background: lighten(#B2C026, 20%);
}

.title {
  display: flex;
  align-items: center;
  column-gap: 5px;
  text-align: left;
  font-size: 14px;
  font-weight: 500;
  font-family: Merriweather, serif;
}

.star {
  color: #65A3BA;
}

.select {
  margin-top: 5px;
  height: 50px;
  box-shadow: none !important;
  border-radius: 4px;
  outline: 1px solid transparent;
  transition: 1s;
}


.selectError {
  outline: 1px solid #d92e2e !important;
}

.errorText {
  font-size: 12px;
  color: #d92e2e;
  position: absolute;
  left: 40px;
  bottom: -20px
}

.disabled {
  opacity: 0.9;
}
