@import '../../../../styles/includes.scss';

.upload__widget {
  padding: 20px 10px 80px 10px;
  max-width: 820px;
  margin: 0 auto;
  text-align: center;
  display: grid;
  row-gap: 30px;
  position: relative;



  &-title {
    font: normal normal bold 33px Merriweather;
    letter-spacing: -0.44px;
    color: #1F1E1D;
    opacity: 1;
    margin: 0;
    @include screen(md) {
      font-size: 44px;
    }
  }

  &-subtitle {
    font: normal normal normal 19px Merriweather;
    letter-spacing: 0;
    color: #1F1E1D;
    margin: 10px 0 0 0;
    @include screen(md) {
      font-size: 22px;
      margin-top: 0;
    }
  }

  &-fields-wrap {
    display: grid;
    grid-template-columns: 1fr;
    column-gap: 50px;
    row-gap: 30px;
    width: 100%;
    margin: 0 auto;
    max-width: 600px;
    @include screen(md) {
      grid-template-columns: 1fr 1fr;
    }
  }

  &-subtext {
    background: #B2C026;
    padding: 8px 20px;
    color: #fff;
  }

  &-loader {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: 100;
  }
}