.icon {
  width: 30px;
  height: 30px;
  border: 4px solid dodgerblue;
  position: relative;
  cursor: pointer;
}

.dec {
  position: absolute;
  width: 20px;
  height: 20px;
  background: #fff;
  right: -5px;
  top: -5px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 1s;
}

.icon:hover .lineLeft,
.icon:hover .lineRight {
  background: lighten(dodgerblue, 20%);
}

.icon:hover .dec {
  right: -8px;
  top: -8px;
}

.line,
.lineLeft,
.lineRight {
  position: absolute;
  height: 100%;
  width: 4px;
  background: dodgerblue;
}

.line {
  transform: rotate(45deg);
  border-top-left-radius: 3px;
  border-top-right-radius: 3px;
}

.lineLeft {
  transform: rotate(90deg) translateX(-9px) translateY(-3px);
  height: 70%;
  transition: 1s;
}

.lineRight {
  transform: rotate(0deg) translateX(9px) translateY(-3px);
  height: 70%;
  transition: 1s;
}


