@import '../../../../../../styles/includes.scss';

.customary-practice__content {   

    .customary-practice__content-main {
        max-width: 800px;
    }

    .customary-practice__rules-navigation {
        display: none;
        justify-content: space-between;
        padding: 30px 0;
        font-size: 18px;
        line-height: 22px;
        font-family: "Open Sans";

        @include screen(md) {
            display: flex;
        }
        
        a {
            color: #62A3BB;
            text-decoration: none;
            font-weight: 600;
            letter-spacing: 0;
        }

        .customary-practice__rules-navigation--trimmed {
            width: 120px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            display: inline-block;
            margin-bottom: -5px;
        }
    }

    .customary-practice__related-title {
        font-size: 35px;
        line-height: 38px;
        color: #1F1E1D;
        margin-bottom: 0;
        @include screen(md) {
            font-size: 30px;
            line-height: 35px;
            margin: 0;
        }
    }

    .customary-practice__content-title {
        margin-top: 0;
        @include screen(md) {
            font-weight: 400;
            margin-top: 15px;
        }
    }

    .customary-practice__note {
        font-size: 12px;
        line-height: 18px;
        color: #6F6969;
        font-family: 'Open Sans';

        i {
            font-style: normal; 
        }    
    }
    .customary-practice__summery {

        .customary-practice__section-title {
            font-size: 24px;
            line-height: 28px;
            color: #1F1E1D;
            margin-bottom: 15px;
            padding-top: 260px;
            margin-top: -250px;
        }

        p {
            font-size: 15px;
            line-height: 24px;
            word-wrap: break-word;
        }

        .Note {
            color: #6F6969;
            font-size: 12px;
            line-height: 18px;
        }

        .footnote {
            color: #6F6969;
            font-size: 12px;
            line-height: 18px;
            margin: 15px 0;
        }

        .customary-practice__summery-section {
            h3 {
                font-size: 19px;
                line-height: 26px;
                color: #62A3BB;
                padding-top: 260px;
                margin-top: -250px;
                margin-bottom: 15px;
                @include screen(md) {
                    font-size: 24px;
                    line-height: 28px;
                }
            }
            h4 {
                font-size: 16px;
                line-height: 28px;
                color: #000000;
                margin-bottom: 15px;
                padding-top: 260px;
                margin-top: -260px;
                @media print {
                    margin-top: 0;
                    padding-top: 0;
                }
            }
            img {
                display: none;
            }
            p {
                text-align: justify;
                text-justify: inter-word;

                .Quote {
                    padding-left: 30px;
                    padding-bottom: 5px;
                }

                .QuoteWQuote {
                    padding-left: 60px;
                    margin-top: 1em;
                    margin-bottom: 1em;
                }

                .QuoteWQuoteIndent {
                    padding-bottom: 5px;
                    padding-left: 90px;
                }
                
                .QuoteWQuoteDoubleIndent{
                    padding-left: 120px;
                    padding-bottom: 5px;
                }

                .green {
                    color: #088a08;
                }

                .QuoteGreen {
                    color: #088a08;
                    padding-left: 30px;
                }

                .Normal6pt {
                    padding: 6px 0 5px 0;
                }

                .Normal {
                    padding: 10px 0 5px 0;
                }
            }
        }
    }

    .customary-practice__float {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
        z-index: 2;
        left: 0;
        height: 56px;
        border-top: 1px solid rgba(112, 112, 112, 0.2);
    
        .customary-practice__navigation-button {
            width: 25%;

            a {
                color: #6F6969;
                text-decoration: none;
            }

            i {
                font-size: 16px;
                line-height: 26px;
            }
        }

        .customary-practice__navigation-button:nth-child(3) {
            justify-content: flex-end;
            text-align: right;
        }
        
        .customary-practice__additional {
            @include typography(t7);
            font-weight: 600;
            line-height: 25px;
            text-transform: uppercase;
            height: 56px;
            display: flex;
            width: 50%;
            align-items: center;
            padding: 0 10px;
            color: $white;
            background-color: #62A3BB;
    
          div {
                padding-left: 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }
        .customary-practice__navigation {
            @include typography(p4);
            background-color: white;
            color: #6f6969;
            height: 56px;
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            align-items: center;
        }
            @include screen(md) {
                display: none;
            }
        }

        .customary-practice__show-modal {
            background-color: #62A3BB;
            font-size: 15px;
            line-height: 28px;
            color: white;
            padding: 10px 30px;
            display: flex;
            min-width: 200px;
            justify-content: space-between;
            margin-left: auto;
            margin-bottom: 30px;
            @include screen(md) {
                display: none;
            }
        }

        .customary-practice__expanded-content {
            display: block;
            height: 100%;
            position: fixed;
            z-index: 20;
            background-color: rgba(0, 0, 0, 0.6);
            top: 50px;
            right: 0;
            left: 0;
            overflow-y: scroll;
            padding-bottom: 50px;

        .customary-practice__mobile-sidebar {
            margin-left: 25px;
            background-color: #F8F8F8;
            padding: 15px;
            min-height: 100%;
        }
        
        .customary-practice__sidebar {
            padding: 0;
            background-color: transparent;
            width: 100%;
            margin-left: 0;

            .customary-practice__side-navigation {
                display: block;
            }

            .item {
            display: flex;

            .value {
                    color: #6F6969;         
                }
            }
        }
    
        .customary-practice__close-modal {
            background-color: #62A3BB;
            font-size: 15px;
            line-height: 28px;
            color: white;
            padding: 10px 30px;
            display: flex;
            width: 170px;
            justify-content: space-between;
            margin-left: auto;
            margin-right: -15px;
        }

        .customary-practice__mobile-sidebar-heading {
            border-bottom: 1px solid rgba(112, 112, 112, 0.2);
            margin-bottom: 30px;

            .customary-practice__related-button {
                color: #ffffff;
                background-color: #62A3BB;
                font-size: 13px;
                line-height: 12px;
                padding: 17px 19px 16px;
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
                width: 215px;
                margin-bottom: 15px;
                margin-top: 15px;
                text-decoration: none;

                .icon {
                    font-size: 15px;
                    line-height: 15px;
                    height: 12px;
                }
            }
        }
    }
}