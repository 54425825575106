.wrap {
    padding: 8px;
    color: #fff;
    font-size: 10px;
    line-height: 12px;
    text-transform: uppercase;
    font-family: 'Open Sans';
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: #24BC1E;
    transition: 0.5s;
    cursor: pointer;
    position: relative;
}

.wrap:after {
    content: '';
    position: absolute;
    right: -20px;
    height: 63%;
    width: 15px;
    border-right: 10px solid transparent;
    border-top: 28px solid #24BC1E;
    top: 0;
    transition: 0.5s;
}
