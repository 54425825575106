@import '../../../../../../styles/includes.scss';

.customary-practice__content {
    .customary-practice__rules-mobile-header {
        display: block;
        padding-bottom: 15px;
        border-bottom: 1px solid rgba(112, 112, 112, 0.2);
        @include screen(md) {
            display: none;
        }

        .customary-practice__rules-side-navigation {
            display: block;
        }

        .customary-practice__related-button {
            color: #ffffff;
            background-color: #62A3BB;
            font-size: 13px;
            line-height: 12px;
            padding: 17px 19px 16px;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            width: 215px;
            margin-bottom: 15px;
            margin-top: 15px;
            text-decoration: none;

            span {
                display: flex;
                flex: 1;
                color: #dedede;
                font-size: 80%;
                font-weight: 400;
            }

            .icon {
                font-size: 15px;
                line-height: 15px;
                height: 12px;
            }
        }

        .customary-practice__navigation-item  {
            font-size: 15px;
            line-height: 24px;
            margin-bottom: 15px;
            font-weight: 600;
            display: block;
            color: #62A3BB;
            cursor: pointer;
            position: relative;
            text-decoration: none;
        }
    }
    .customary-practice__rules-summery-section {
        text-align: justify;
        text-justify: inter-word;

        h3 {
            font-size: 16px;
            line-height: 28px;
            color: #000000;
            font-family: 'Open Sans';
        }

        p {
            font-size: 15px;
            line-height: 24px;            
        }

        .L3 {
            padding-top: 220px;
            margin-top: -205px;
            color: #62A3BB;
            font-size: 24px;
            line-height: 28px;
            font-family: 'Merriweather';
        }

        .Intro {
            font-size: 12px;
            line-height: 18px;
            font-weight: 900;
            @include screen(md) {
                font-size: 20px;
                line-height: 28px;
            }
        }

        .footnoteBlock {
            color: #65686F;
            font-size: 13px;
            line-height: 22px;
            margin-top: 30px; 
            margin-bottom: 40px;            
            
            .footnoteAtEnd {
                margin-bottom: 10px;
                margin-top: -220px;
                padding-top: 220px;
                @media print {
                    margin-top: 0;
                    padding-top: 0;
                }
            }
        }

        a {
            color: #62A3BB;
            text-decoration: none;
        }

        .Normal {
            padding: 10px 0 5px 0;
        }

        .NormalUnderline {
            text-decoration: underline;
            padding: 6px 0 5px 0;
        }

        .NormalBullet {
            padding-left: 10px;
            margin-left: 10px;
            list-style-type: none;
            background-color: transparent;
            display: block;
        }
    }
    .customary-practice__float {
        .customary-practice__additional {
            justify-content: center;
        }
    }
    .customary-practice__expanded-content-rules {
        display: block;
        height: 100%;
        position: fixed;
        z-index: 20;
        background-color: white;
        top: 50px;
        right: 0;
        left: 0;
        overflow-y: scroll;
        padding-bottom: 50px;

        .customary-practice__actual {
            font-family: 'Open Sans';
            letter-spacing: -0.14px;
            font-weight: 600;
            font-size: 14px;
            line-height: 25px;
            text-transform: uppercase;
            height: 56px;
            display: flex;
            align-items: center;
            padding: 0 10px;
            color: #fff;
            background-color: #62A3BB;
        }

        .customary-practice__expanded-content-list {
            padding: 0px 24px;

            .style_title__axdvG, .style_collapse__3eV5H {
                display: none;
            }

            .style_wrap__q2AGA {
                margin-bottom: 0;
                &:first-child {
                    display: none;
                }
            }

            .style_titleWrap__368qT {
                display: none;
            }

            .style_header__3ZsEN {
                display: none;
            }

            .style_wrap__2OR44 {
                padding: 12px 0px;
                border-bottom: 1px solid silver;
                background-color: transparent;
                font-weight: 600;
                max-width: calc(100vw - 48px);
            }

            .style_wrap__jD-DM {
                padding-top: 0;
            }

            .customary-practice__rule-title { 
                @include screen(md) {
                    white-space: nowrap;
                    overflow: hidden;
                    text-overflow: ellipsis;
                }
            }
        }
    }
}

.customary-practice__rules-top {
    display: flex;
    flex-wrap: wrap;
    margin-top: 30px;
}