@import '../../../../styles/includes.scss';

.top-menu {
  display: none;

  @include screen(md) {
    display: block;
    height: 74px;
    margin: 8px 12px !important;
  }
  .header-option {
    max-height: 60px;

    .topmenu-username {
      font-size: 14px;

      a {
        color: white;
         white-space: nowrap;
      }
      &.notWhite a {
        color: $black;
      }
    }
    .username-placeholder {
      min-height: 6px;
    }
  }
  .top-menu__logo {
    img {
      height: 50px;
      width: auto;
      padding: 0 5px;

      @include screen(sm) {
        padding-top: 12px;
        height: 80px;
      }
    }
    .logo-content {
      width: 81px;
      background-color: white;
      height: 91px;
      margin-top: -8px;
      margin-bottom: -11px;
      a {
        display: flex;
        justify-content: center;
      }
    }
  }

  .top-menu__title {
    padding: 5px;
    align-self: flex-end;
  }

  .top-menu__title--primary {
    @include typography(t5);
    display: block;
    @include screen(sm) {
      font-size: 27px;
      font-size: calc(min(27px, 2vw));
      padding-top: 5px;
      &.white {
        color: $white;
      }
    }
  }

  .top-menu__title--secondary {
    font-size: 9px;
    color: #dcdcdc;
    display: block;
    @include screen(sm) {
      @include typography(t6);
    }
  }

  .top-menu__items {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: center;

    @include screen(sm) {
      justify-content: space-between;
    }
  }

  .top-menu__items--right-alignment {
    text-align: right;
    padding-right: 6px;
  }

  .top-menu__flex {
    display: flex;
    align-items: baseline;

    .top-menu__citation,
    .top-menu__credits {
      text-decoration: none;
      color: white;
      font: normal normal bold 12px/12px Open Sans;
      letter-spacing: 0.5px;
      margin-right: 16px;
      cursor: pointer;
      &.notWhite {
        color: $black;
      }
    }
  }

  .top-menu__language {
    font-size: 12px;
    line-height: 12px;
    .language-selector {
      background-color: transparent;
      &.notWhite {
        color: $black;
      }
      option {
        color: $black;
      }
    }

    & > * {
      text-transform: uppercase;
      @include screen(sm) {
        text-transform: capitalize;
        margin-top: 3px;
      }
      color: $white;
      font: normal normal bold 12px/12px Open Sans;
      letter-spacing: 0.5px;
    }
  }

  .top-menu__extra-links {
    display: none;
    text-transform: uppercase;

    & > * {
      margin: 5px;
    }

    @include screen(sm) {
      display: flex;
      justify-content: flex-end;
      .button-header {
        text-decoration: none;
        border: 1px solid #707070;
        width: 121px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $black;
        background-color: $white;
        &:hover {
          border: 1px solid $lightBlack;
          color: $lightBlack !important;
        }

        span {
          font: normal normal bold 10px/12px Open Sans;
          letter-spacing: 0px;
          padding-right: 10px;
        }

        .icon {
          width: 20px;
          height: 25px;
          font-size: 15px;
        }
      }
    }

    .button--button-header {
      text-decoration: none;
      border: 1px solid #707070;
      width: 121px;
      height: 34px;
      display: flex;
      justify-content: center;
      align-items: center;
      color: $black;
      background-color: $white;
      border-radius: 0;

      .button__label {
        font: normal normal bold 10px/12px Open Sans;
        letter-spacing: 0px;
        padding-right: 10px;
        text-transform: uppercase;
      }

      .icon {
        width: 20px;
        height: 25px;
        font-size: 15px;
      }
    }
  }
}
