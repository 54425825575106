@import '../../../../../../styles/includes.scss';

.national-practice__detail {
  display: block;
  min-height: 500px;

  .national-practice__detail-wrap {
    display: grid;
    grid-template-columns: 1fr;

    @include screen(md) {
      grid-template-columns: 4fr 1fr;
    }
  }



  .loader {
    padding-top: 150px;
  }

  .national-practice__content {
    width: 100%;
    padding-left: 4px;
    padding-right: 4px;
    max-width: 850px;

    @include screen(md) {
      padding-right: 40px;
    }

    h1 {
        font-size: 35px;
        line-height: 46px;
        color: #1F1E1D;
        margin-bottom: 0;
        margin-top: 15px;

        @include screen(md) {
          margin-top: 0;
          font-size: 30px;
          line-height: 35px;
        }
    }
  }

  .national-practice-float {
    display: block;
    position: fixed;
    bottom: 0;
    right: 0;
    left: 0;
    height: 56px;

    .national-practice__navigation-button {
      width: 25%;
      opacity: 0;
    }

    .national-practice__navigation-button:nth-child(3) {
      justify-content: flex-end;
      text-align: right;
    }

    .national-practice__additional {
      @include typography(t7);
      font-weight: 600;
      line-height: 25px;
      text-transform: uppercase;
      height: 56px;
      display: flex;
      width: 50%;
      align-items: center;
      padding: 0 10px;
      color: $white;
      background-color: #62A3BB;

      div {
        padding-left: 5px;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }
    .national-practice__navigation {
      @include typography(p4);
      background-color: white;
      color: #6f6969;
      height: 56px;
      display: flex;
      justify-content: space-between;
      padding: 0 10px;
      align-items: center;
    }
    @include screen(md) {
      display: none;
    }
  }

  .national-practice__show-modal {
    width: 100%;
    background-color: #62A3BB;
    font-size: 15px;
    line-height: 28px;
    color: white;
    padding: 10px 30px;
    display: flex;
    min-width: 200px;
    justify-content: space-between;
    margin-left: auto;
    margin-right: -20px;
    margin-bottom: 30px;

    @include screen(md) {
      display: none;
    }
  }

  .national-practice__expanded-content {
    display: block;
    height: 100%;
    position: fixed;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.6);
    top: 50px;
    right: 0;
    left: 0;
    overflow-y: scroll;
    padding-bottom: 50px;

    .national-practice__mobile-sidebar {
      margin-left: 25px;
      background-color: #F8F8F8;
      padding: 15px;
      min-height: 100%;
    }

    .national-practice__sidebar {
      padding: 0;
      background-color: transparent;
      width: 100%;
      margin-left: 0;
      display: block;

      .item {
        display: flex;

        .value {
          color: #6F6969;
        }
      }
    }

    .national-practice__close-modal {
      background-color: #62A3BB;
      font-size: 15px;
      line-height: 28px;
      color: white;
      padding: 10px 30px;
      display: flex;
      width: 170px;
      justify-content: space-between;
      margin-left: auto;
      margin-right: -15px;
    }

    .share {
      display: none;
    }

    @include screen(md) {
      display: none;
    }
  }
}
