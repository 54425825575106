@import '../../../../styles/includes.scss';

.map {
  padding-bottom: 30px;
  .map__filter {
    max-width: 100%;
    @include screen(md) {
      max-width: 435px;
    }
    display: flex;
    align-items: center;
    margin: 0 auto;
    .input {
      border: 0;
      border-bottom: 1px solid grey;
      border-radius: 0;
    }
    .icon {
      color: $bostonBlue;
      padding-left: 10px;
      cursor: pointer;
    }
  }
  .map__toggle-open {
    color: $bostonBlue;
    font: normal normal bold 12px/15px Merriweather;
    display: block;
    width: 150px;

    cursor: pointer;
    .open {
      display: block;
      width: 150px;
    }
  }

  .map__toggle-close {
    color: $bostonBlue;
    font: normal normal bold 12px/15px Merriweather;
    display: block;
    cursor: pointer;

    .close {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: auto;
    }
  }
  .map-container {
    border: 1px solid $lightAlto;
    &.expanded {
      width: 100%;
      height: 70vh;
      @include screen(md) {
        height: 680px;
      }
    }
    &.collapsed {
      width: 100%;
      height: 150px;
      @include screen(md) {
        width: 400px;
      }
    }
    .mapboxgl-popup-content {
      width: max-content;
      h2 {
        font: normal normal bold 18px/24px Merriweather;
        color: $bostonBlue;
        margin: 0 0 10px 0;
      }
      p {
        margin: 5px 0;
        font: normal normal bold 10px/15px Merriweather;
      }
      a {
        color: $bostonBlue;
        text-decoration: none;
        font-size: 12px;
      }
    }
  }
  .map__toggle-border {
    width: 0;
    height: 0;
    border-top: 40px solid transparent;
    border-bottom: 40px solid transparent;
    border-left: 40px solid $white;
    z-index: 3;
    position: absolute;
    bottom: -26px;
    right: -6px;
    transform: rotate(45deg);
    cursor: pointer;
  }
  .arrow {
    border: solid $bostonBlue;
    border-width: 0px 1px 1px 0;
    display: inline-block;
    padding: 12px;
    position: absolute;
    z-index: 4;
    bottom: 8px;
    right: 8px;
    cursor: pointer;
    &.rotate {
      transform: rotate(180deg);
      -webkit-transform: rotate(180deg);
      padding: 8px;
    }
  }
  .map__labels {
    background-color: #ffffffad;
    position: absolute;
    z-index: 3;
    bottom: 100px;
    left: 30px;
    border: 1px solid $lightAlto;
    padding: 5px 25px;
    max-width: 280px;

    @include screen(md) {
      background-color: $white;
      min-width: 325px;
      bottom: 50px;
    }

    .line:not(:last-child) {
      border-bottom: 1px solid $lightAlto;
    }
    .line {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .label-color {
        width: 10px;
        height: 10px;
      }
      div {
        margin: 5px 0;
        font: normal normal bold 10px/15px Merriweather;
      }
      img {
        width: 12px;
        margin-right: 10px;
      }
      .first {
        display: flex;
        align-items: center;
      }
      .visible {
        text-transform: uppercase;
        font: normal normal normal 10px/15px Open Sans;
        cursor: pointer;
        color: #00000061;
        .blue {
          color: $bostonBlue;
        }
        span {
          display: flex;
          align-items: center;
        }
        img {
          margin-right: 5px;
        }
      }
    }
  }
  .marker {
    margin-top: 10px;
    background-size: cover;
    width: 10px;
    height: 10px;
    cursor: pointer;
    &.state-party {
      background-image: url('../../../../assets/images/state-party.png');
    }
    &.signature {
      background-image: url('../../../../assets/images/signatory.png');
    }
    &.declaration90 {
      background-image: url('../../../../assets/images/declaration90.png');
      width: 7px;
      height: 7px;
    }
    &.other {
      background-image: url('../../../../assets/images/other.png');
    }
  }

  .mapboxgl-ctrl-bottom-right {
    margin-right: 10%;
    left: 108px;

    @include screen(sm) {
      background-color: transparent;
      margin-right: 5%;
    }

    .mapboxgl-compact {
      max-height: 85px;
      overflow: auto;
      @include screen(sm) {
        background-color: transparent;
      }
      background-color: $white;
    }

    .mapboxgl-ctrl-attrib-button {
      @include screen(sm) {
        display: none;
      }
      display: block;
    }
  }
}
