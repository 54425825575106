.header {
  padding: 12px 3px;
  color: #4AA4B9;
  border-bottom: 1px solid silver;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 17px;
  font-weight: 700;
  justify-content: space-between;
}

.title {
  color: #252525;
}

.dropDown {
  font-size: 21px;
  transition: 0.5s;
  cursor: pointer;
  width: 30px;
  height: 30px;
  border: 1px solid transparent;
  border-radius: 50%;
}

.dropDown:hover {
  border: 1px solid #4AA4B9;
}

.element {
  background-color: #F0F0F0;
  padding: 12px 20px;
  color: #4AA4B9;
  border-bottom: 1px solid silver;
  display: flex;
  align-items: center;
  column-gap: 5px;
  font-size: 15px;
}