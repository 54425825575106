@import '../../../../../styles/includes';

.wrap {
  height: 200px;
  background: #f2f7f7 0 0 no-repeat padding-box;
  border: 2px dashed #65A3BA;
  width: 100%;
  border-radius: 8px;
  max-width: 680px;
  margin: 0 auto;
  padding: 20px;
  position: relative;
}

.size-error {
  color: #d92e2e
}

.wrapError {
  border: 2px dashed #d92e2e;
}

.title {
  margin: 3px 0;
  font: normal normal bold 30px Merriweather;
  letter-spacing: 0;
  color: #62A3BB;
  opacity: 1;

}

.info {
  font: normal normal bold 12px Merriweather;
  margin: 0;
  color: #62A3BB;
  display: none;
  @include screen(md) {
    font-size: 17px;
    margin: 3px 0;
    display: block;
  }
}

.text {
  font: normal normal bold 40px Merriweather;
  font-size: 16px;
  color: #62A3BB;
}


.button {
  background: #65A3BA;
  padding: 8px 20px;
  font: normal normal bold 23px Merriweather;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 160px;
  position: absolute;
  bottom: -25px;
  left: 50%;
  color: aliceblue;
  transform: translateX(-50%);
  cursor: pointer;
}

.buttonError {
  background: #d92e2e;
}

.clear {
  position: absolute;
  right: 15px;
  top: 10px;
  width: 15px;
  height: 15px;
  cursor: pointer;
}

.clear:before,
.clear:after {
  content: '';
  height: 15px;
  width: 3px;
  position: absolute;
  background: #d92e2e;

}

.clear:hover {
  opacity: 1;
  letter-spacing: 1px;
}

.clear:before {
  transform: rotate(45deg);
}

.clear:after {
  transform: rotate(-45deg);
}


.disabled {
  opacity: 0.3;
}