@import '../../../../../../styles/includes.scss';

.wrap {
  padding: 12px 20px;
  color: #4AA4B9;
  border-bottom: 1px solid silver;
  display: grid;
  align-items: flex-start;
  grid-template-columns: 60px 1fr;
  column-gap: 5px;
  background-color: #F0F0F0;
  font-family: Merriweather, sans-serif, arial;
  font-size: 12px;
  line-height: 28px;
  font-weight: 500;
  cursor: pointer;
  text-decoration: none;

  @include screen(md) {
    white-space: initial;
  }
}

.wrapActive {
    color: lighten(green, 10%);
}
