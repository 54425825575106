@import '../../../../../../styles/includes.scss';

.customary-practice__states-visible-switch {
    display: flex;
    align-items: center;
    margin-top: 15px;

    .customary-practice__states-visible-label {
        font-size: 12px;
        line-height: 18px;
        color: #1F1E1D;
        font-weight: 600;
        padding-left: 11px;
        padding-right: 18px;
        cursor: pointer;
    }

    .customary-practice__states-updated-flag {
        padding: 8px;
        color: #fff;
        font-size: 10px;
        line-height: 12px;
        text-transform: uppercase;
        font-family: 'Open Sans';
        border: none;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #B2B2B2;
        transition: 0.5s;
        cursor: pointer;
        text-transform: uppercase;
        position: relative;

        &::after {
            content: '';
            position: absolute;
            right: -20px;
            height: 63%;
            width: 15px;
            border-right: 10px solid transparent;
            border-top: 28px solid #B2B2B2;
            top: 0;
            transition: 0.5s;
        }
        &-active {
            background-color: #24BC1E;

            &::after {
                border-top: 28px solid #24BC1E;
            }
        }
    }

      .buttonActive {
        background-color: #24BC1E;

        &::after {
            border-top: 28px solid #24BC1E;
        }
    }
}
