.wrap {
    height: 37px !important;
    width: 28px !important;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
}

.light {
    background-color: #B1BF26;
}

.dark {
    background-color: darken(#B1BF26, 10%);
}

.wrapBlocked {
    background-color: silver;
}

.checkbox {
    cursor: pointer;
}
