@import 'src/styles/includes';

.list-customary-state-title {
    font-size: 25px;
    font-family: Merriweather, sans-serif, arial;
}

#list-customary-ihl-state {
    .list-customary-state-link {
        text-decoration: none !important;
    }
}


.list-customary-state-empty {
    font-size: 15px;
    font-family: Merriweather, sans-serif, arial;
    color: #cc3300;
}

.list-customary-state-item {
    border-top: 1px solid #E7E7E3;
    font-family: Merriweather, serif;
    font-weight: bold;
    font-size: 16px;
    color: #4AA4B9;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    column-gap: 10px;
    padding: 15px;
    align-items: center;
    flex-direction: column;
    row-gap: 10px;

    &:hover {
        background: #E7E7E3;
    }

    &-updated {
        color: #24BC1E;
    }

    &-link {
        color: #4AA4B9;
        text-decoration: none;
    }

    @include screen(md) {
        flex-direction: row;
        row-gap: 0px;
    }
}

.customary-practice__back-wrap {
    font-size: 12px;
    line-height: 22px;
    font-weight: 600;
    z-index: 1;

    a {
        color: #62A3BB;
        text-decoration: none;
        font-weight: 600;
        letter-spacing: 0;
        cursor: pointer;
    }
}
