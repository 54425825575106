@import '../../styles/includes.scss';

@media print {
    .noprint {
        display: none !important;
    }

    .treaty__side-content {
        page-break-before: always;
        break-before: page;
        padding: 0 !important;

        .item {
            display: block !important;
        }
    }
}
