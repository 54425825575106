@import '../../../../styles/includes';

.group-item {
  color: $white;
  padding: 30px 20px;
  display: flex;
  flex-direction: column;

  &.treaties {
    background-color: map-get($treaties, m-color) !important;
  }
  &.customary {
    background-color: map-get($customary, m-color) !important;
  }
  &.national {
    background-color: map-get($national, m-color) !important;
  }
  &.search {
    background-color: map-get($search, m-color) !important;
  }

  @include screen(sm) {
    &.group-item--grey {
      background-color: #c7c7c7;
    }
  }

  a {
    text-decoration: none;
    cursor: pointer;
    height: 100%;
  }

  .group-item__top {
    display: flex;
    justify-content: space-between;
    font-size: 30px;
  }

  .group-item__title {
    @include typography(t2);
    text-align: left;
    color: $white;
  }
  .group-item__icon{
    display: inline-block;
    color: $white;
    @include screen(sm) {
      display: none;
    }
  }

  .group-item__actions {
    display: none;
    @include screen(sm) {
      display: block;
      padding-top: 30px;
      .button {
        font: normal normal bold 13px/12px Merriweather;
        letter-spacing: 0.13px;
        width: 192px;
        height: 57px;
        border-radius: 0;
        color: $white;
      }
    }
  }

  @include screen(sm) {
    height: 199px;
    min-height: 157px;
    .group-item__icon {
      display: none;
    }
  }
}
