.wrap {
  width: 50px;
  height: 50px;
  background: red;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  font-weight: 800;
  border: 5px solid #a50044;
  color: #fff;
  transition: 0.5s;
  cursor: pointer;
}

.wrap:hover {
  border: 5px solid red;
}

.wrapDisabled {
  background: #a50044;
  opacity: 0.3;
}