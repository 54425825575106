.wrap {
  height: 28px;
  background: silver;
  width: 48px;
  border-radius: 15px;
  position: relative;
}

.toggle {
  top: 3px;
  height: 22px;
  width: 22px;
  position: absolute;
  left: 3px;
  background: lightgrey;
  transition: 0.5s;
  border-radius: 50%;
  cursor: pointer;
}

.toggleActive {
  background: lighten(#B2C026, 20%);
  left: 23px;
}
