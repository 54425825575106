@import '../../../../../../frontend/src/styles/includes.scss';

.custom-button {
  display: inline-block;
  outline: none;
  box-shadow: none;
  text-align: center;
  border: 3px solid transparent;
  white-space: nowrap;
  cursor: pointer;
  color: $white;
  border-radius: 0;
  background: #B2C026;

  &:hover {
    background: lighten(#B2C026, 20%);
  }

  @include typography(p2);
  padding: 2px 25px;

  @include theme(map-get($treaties, name)) {
    background-color: map-get($treaties, m-color);
  }
  @include theme(map-get($customary, name)) {
    background-color: map-get($customary, m-color);
  }
  @include theme(map-get($national, name)) {
    background-color: map-get($national, m-color);
  }
  @include theme(map-get($upload, name)) {
    background-color: map-get($upload, m-color);
  }

  &.disabled {
    transition: 0.4s;
    opacity: 0.5;
    pointer-events: none;
    cursor: default;
  }

  &.active {
    transition: 0.4s;
  }

  &:hover {
    transition: 0.4s;
    background-color: darken($color: map-get($treaties, m-color), $amount: 10);
  }

  &:active {
    transition: 0.4s;
  }
}
