
.topSection {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    row-gap: 10px;
    width: 100%;
    max-width: 1220px;
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    z-index: 19;
    background: #fff;
    padding: 20px 10px;
}

.iconLess {
    font-size: 12px;
    margin-left: 5px;
    color: silver;
    transition: 0.5s;
    opacity: 0;
}

.iconLessRevert {
    transform: rotate(180deg);
}

.titleWrap:hover .iconLess {
    opacity: 1;
}

.download {
    color: darkblue;
    cursor: pointer;
}

.iconLess:hover {
    cursor: pointer;
    color: #262626;
}

.actionWrap {
    display: grid;
    grid-template-columns: 1fr 1fr;
    row-gap: 10px;
    column-gap: 10px;
}

.link {
    color: blue;
    font-weight: bold;
    cursor: pointer;
}

@media screen and (max-width: 1100px) {
    .topSection {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: 820px) {
    .topSection {
        grid-template-columns: 1fr;
    }
}
