@import '../../../../styles/includes.scss';

.cihl-sources-list {
  display: flex;
  flex-wrap: wrap;

  .cihl-sources-list_main {
    flex: 1;
    padding-right: 50px;
    padding-bottom: 50px;
    max-width: calc(100% - 300px);

    @media print {
      max-width: 100%;
    }

    h1 {
      font-size: 44px;
    }

    p {
      text-align: justify;
      text-justify: inter-word;
    }

    .title {
      color: #62A3BB;
      text-decoration: none;
      margin-bottom: 20px;
      display: block;
      font-size: 22px;
      font-weight: bold;
    }

    .subtitle {
      font-size: 13px;
      line-height: 22px;
      color: #585858;
      
      @include screen(md) {
        line-height: 150%;
      }

      a {
        color: #62A3BB;
        text-decoration: none;
        font-weight: 600;
        word-break: break-all;
      }
    }

    .updates {
      font-size: 12px;
      color: #6E7178;

      @include screen(md) {
        font-size: 13px;
      }

      .bold {
        font-weight: bold;
      }

      li {
        margin: 5px 0;
      }
    }

    .custom-button {
      background-color: #76A5B9;
      text-decoration: none;
      padding: 5px 20px;

      span {
        position: relative;
        margin-top: 1px;
      }

      &:hover {
        background-color: #29B0D8;
      }

      i {
        margin-left: 8px;
      }
    }
  }

  .cihl-sources-list_sidebar {
    flex: 0;

    .share {
      margin-top: 10px;

      hr {
        display: none;
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .cihl-sources-list {
    display: block;
    padding-top: 1px;

    .cihl-sources-list_sidebar {
      background-color: #f5f5f5;
      padding: 0 24px;
      margin-left: -20px;
      width: calc(100% + 40px);
      max-width: calc(100% + 40px);

      .cihl-sidebar {
        width: 100%;
      }
    }
    .cihl-sources-list_main {
      max-width: inherit;
      padding: 0 4px;
    }
  }
}

html[dir="rtl"] {
  .cihl-sources-list_main {
    @include screen(md) {
      padding-right: 0px;
      padding-left: 50px;
    }
  }
}
