@import '../../../../../../styles/includes.scss';

.customary-practice__details {
    display: flex;
    flex-direction: column;
    min-height: 500px;
    justify-content: center;

    @include screen(md) {
        flex-direction: row;
    }

    .loader {
        padding-top: 150px;
    }

    .customary-practice__content {
        width: 100%;
        padding-left: 4px;
        padding-right: 4px;

        @include screen(md) {
            width: 80%;
            padding-left: 0;
            padding-right: 24px;
        }

        h1 {
            font-size: 35px;
            line-height: 38px;
            color: #1F1E1D;
            margin-bottom: 0;
            margin-top: 0px;

            @include screen(md) {
                font-size: 30px;
                line-height: 35px;
            }
        }
    }

    .customary-practice-float {
        display: block;
        position: fixed;
        bottom: 0;
        right: 0;
        left: 0;
        height: 56px;

        .customary-practice__navigation-button {
            width: 25%;
        }

        .customary-practice__navigation-button:nth-child(3) {
            justify-content: flex-end;
            text-align: right;
        }

        .customary-practice__additional {
            @include typography(t7);
            font-weight: 600;
            line-height: 25px;
            text-transform: uppercase;
            height: 56px;
            display: flex;
            width: 50%;
            align-items: center;
            padding: 0 10px;
            color: $white;
            background-color: #62A3BB;

            div {
                padding-left: 5px;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }
        }

        .customary-practice__navigation {
            @include typography(p4);
            background-color: white;
            color: #6f6969;
            height: 56px;
            display: flex;
            justify-content: space-between;
            padding: 0 10px;
            align-items: center;
        }

        @include screen(md) {
            display: none;
        }
    }

    .customary-practice__show-modal {
        background-color: #62A3BB;
        font-size: 15px;
        line-height: 28px;
        color: white;
        padding: 10px 30px;
        display: flex;
        min-width: 200px;
        justify-content: space-between;
        margin-left: auto;
        margin-bottom: 30px;

        @include screen(md) {
            display: none;
        }
    }

    .customary-practice__expanded-content {
        display: block;
        height: 100%;
        position: fixed;
        z-index: 20;
        background-color: rgba(0, 0, 0, 0.6);
        top: 50px;
        right: 0;
        left: 0;
        overflow-y: scroll;
        padding-bottom: 50px;

        .customary-practice__mobile-sidebar {
            margin-left: 25px;
            background-color: #F8F8F8;
            padding: 15px;
            min-height: 100%;
        }

        .customary-practice__sidebar {
            padding: 0;
            background-color: transparent;
            width: 100%;
            margin-left: 0;

            .item {
                display: flex;

                .value {
                    color: #6F6969;
                }
            }
        }

        .customary-practice__close-modal {
            background-color: #62A3BB;
            font-size: 15px;
            line-height: 28px;
            color: white;
            padding: 10px 30px;
            display: flex;
            width: 170px;
            justify-content: space-between;
            margin-left: auto;
            margin-right: -15px;
        }

        .share {
            display: none;
        }

        @include screen(md) {
            display: none;
        }
    }
}

.customary-practice {
    &__top {
        display: flex;
        align-items: center;
        column-gap: 20px;
    }
    
    .customary-practice__rule-title-prefix {
        display: none;
    }
}

.customary-practice__top {
    display: flex;
    flex-wrap: wrap;

    .customary-practice__back-wrap {
        font-size: 12px;
        line-height: 22px;
        font-weight: 600;
        z-index: 1;

        a {
            color: #62A3BB;
            text-decoration: none;
            font-weight: 600;
            letter-spacing: 0;
            cursor: pointer;
        }
    }

    .customary-practice__country-content {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        font-size: 12px;
        line-height: 22px;

        @include screen(md) {
            flex-direction: row;
            align-items: center;
        }

        .customary-practice__country-content-label {
            font-weight: 600;
            margin-right: 15px;
        }

        .css-1fiiycn-container > div {
            width: 240px;
            height: initial;
        }
    }
}
