.picker {
  height: 40px;
  width: 100%;
  border: 1px solid lightgrey;
  padding: 4px 12px;
  outline: none !important;
  border-radius: 4px;
  transition: 0.5s;
}

.picker:hover,
.picker:focus-visible {
  border: 1px solid grey;
}

.pickerError {
  border: 1px solid #d92e2e !important;
}

.disabled {
  opacity: 0.3;
}