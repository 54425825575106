@import '../../styles/includes.scss';

.alphabetic-scroller {
  width: auto;
  float: left;
  margin-right: 15px;
  margin-bottom: 100%;
  height: auto;
  position: sticky;
  z-index: 11;
  top: 120px;
  max-height: calc(100vh - 220px);
  overflow-y: scroll;
  @include screen(md) {
    top: 210px;
  }

  .alphabetic-scroller__container {
    background-color: $grey;
  }

  .alphabetic-scroller__letter {
    font-family: Open Sans;
    font-size: 15px;
    text-align: center;
    padding: 1px 10px;

    .alphabetic-scroller__letter--normal {
      color: $black;
    }

    .alphabetic-scroller__letter--transparent {
      cursor: not-allowed;
      color: $black;
      opacity: 0.4;
    }

    a {
      text-decoration: none;
    }
  }
}

.alphabetic-scroller::-webkit-scrollbar {
    display: none;
    -ms-overflow-style: none;
    scrollbar-width: none;
}
