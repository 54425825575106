.wrap {
  margin-top: 50px;
  display: grid;
  row-gap: 10px;
  margin-bottom: 70px;
}

.title {
  margin: 0;
  font-family: Merriweather, sans-serif, arial;
  font-size: 15px;
}

.container {
  display: flex;
  column-gap: 10px;
}

.link {
  display: block;
  color: #4AA4B9;
  font-weight: 600;
  font-family: Merriweather, sans-serif, arial;
  margin-top: 5px;
}

.bottomWrap {
  margin-top: 40px;
  display: grid;
  row-gap: 20px;
}

.bottomWrap p {
  margin: 0;
}

@media screen and (max-width: 800px) {
  .container {
    width: 100%;
    flex-direction: column;
    row-gap: 20px;
  }
}