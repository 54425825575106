@import '../../../../../../styles/includes.scss';

.customary-practice__sidebar {
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 30px;
    background-color: $grey;
    padding: 15px 24px;

    @include screen(md) {
        background-color: transparent;
        width:21%;
        padding: 0;
        padding-top: 30px;
        margin-left: 0;
        margin-top: 0;
    }

    a {
        color: #62A3BB;
        text-decoration: none;
    }

    h5 {
        color: #65686F;
        font-size: 15px;
        line-height: 29px;
        margin-bottom: 15px;
    }

    .dropdown__wrapper {
        top: 100px;

        @include screen(md) {
            top: 0;
        }
    }

    .customary-practice__related-button {
        color: #ffffff;
        background-color: #62A3BB;
        font-size: 13px;
        line-height: 12px;
        padding: 17px 19px 16px;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 215px;
        margin-bottom: 15px;

        span {
            display: flex;
            flex: 1;
            color: #dedede;
            font-size: 80%;
            font-weight: 400;
        }

        .icon {
            font-size: 15px;
            line-height: 15px;
            height: 12px;
        }
    }

    .customary-practice__side-navigation {
        display: none;
        @include screen(md) { 
            display: block;           
            overflow: auto;
            max-height: 55vh;
        }
    }

    .customary-practice__navigation-item {
        font-size: 15px;
        line-height: 24px;
        font-weight: 600;        
        display: block;        
        white-space: break-spaces;

        &.selected {
            &::before {
                content: '\25BA';
                font-size: 12px;
                line-height: 12px;
                margin-right: 5px;
            }
        }

    }

    .customary-practice__sidebar-short-navigation {
        .customary-practice__navigation-item:last-child {
            margin-bottom: 30px;
        }
    }

    .expandable__header {
        font-size: 15px;
        line-height: 24px;
        font-weight: 600;
        display: block;
        color: #62A3BB;
        cursor: pointer;
        position: relative;

        .expandable__header-link {
            display: block;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            margin-bottom: 10px;
        }
    }

    .expandable__content {
        .customary-practice__navigation-item:last-child {
            margin-bottom: 30px;
        }
    }

    .customary-practice__related-document {
        span {
            display: block;
        }

        a {
            font-family: Merriweather;
            font-size: 13px;
            line-height: 19px;
            font-weight: bold;

            @include screen(md) {
                font-size: 15px;
                line-height: 24px;
            }
        }
    }

    .item {
        display: none; 
        flex-direction: column;
        padding-bottom: 15px;

        .label {
            font-family: Merriweather;
            font-size: 12px;
            line-height: 28px;
            font-weight: bold;
        }
        .value {
            font-size: 12px;
            line-height: 24px;
            font-weight: normal;
            font-family: 'Open Sans';
            color: #6F6969;
            p {
                margin: 0;
            }
        }
        &.documents,
        &.research-guides {
            .value {
            display: flex;
            flex-direction: column;
            }
        }

        @include screen(md) {
            display: flex; 
        }

        .customary-practice__source-link {
            inline-size: 100%;
            overflow-wrap: break-word;
            display: block;
            margin-bottom: 10px;

            &:last-child {
                margin-bottom: 0;
            }
        }
    }

    .share {
        .value {
            font-size: 15px;
            line-height: 24px;
            font-family: Merriweather;
            color: #6F6969;
        }

        @include screen('custom', 'max', '992px') {
            border-top: none;
            padding: 0;
            display: flex;
            align-items: center;

            .value {
                flex: 1;
                font-weight: bold;
                color: #65686F;
            }

            .button {
                display: none;
            }
        }
    }

    .customary-practice__language {
        margin-right: 5px;
    }
}