@import '../../../../../../styles/includes.scss';

.tabs__header {
    display: flex;
    flex-wrap: nowrap;
    overflow: auto;
}

.tabs__title {
    display: inline-block;
    font-size: 14px;
    line-height: 12px;
    color: #020202;
    opacity: .5;
    font-family: 'Open Sans';
    text-transform: uppercase;
    font-weight: bold;
    text-align: center;
    min-width: 160px;
    margin-right: 10px;
    margin-bottom: 15px;
    cursor: pointer;

    .tabs__title-content {
        padding: 11px;
    }

    &.active {
        border-bottom: 3px solid #2A3675;
        opacity: 1;
        color: #2A3675;
    }
}

.search-results__database-content {
    .search-results__list-item {
        padding: 15px 0;
        border-bottom: 1px solid rgba(112, 112, 122, 0.1);
        max-width: 700px;

        a {
            font-size: 15px;
            line-height: 28px;
            color: #62A3BB;
            font-weight: 600;
            text-decoration: none;
        }

        p {
            font-size: 14px;
            line-height: 19px;
            font-family: 'Open Sans';
            margin-top: 5px;
            margin-bottom: 0;
        }
    }

    .search-results__discalimer {
        display: flex;
        margin: 5px 0;

        @include screen(md) {
            flex-wrap: wrap;
        }

        p {
            font-size: 16px;
            line-height: 20px;
            font-family: 'Open Sans';
            margin-top: 3px;
            margin-bottom: 0;
            margin-left: 5px;
        }
    }
}

.search-results__more-btn {
    background-color: #62A3BB;
    font-size: 13px;
    line-height: 12px;
    display: inline-block;
    margin-top: 15px;
    margin-bottom: 30px;

    a,
    div {
        display: flex;
        text-decoration: none;
        cursor: pointer;

        i {
            color: #ffffff;
            padding-top: 13px;
            margin-right: 20px;
        }
    }

    p {
        padding: 20px;
        color: #ffffff;
        align-items: center;
        margin: 0;

        span {
            margin-left: 4px;
        }
    }
}
