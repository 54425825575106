.wrap {
    display: flex;
    align-items: center;
    column-gap: 5px;
}

.checkbox {
    cursor: pointer;
    width: 20px;
    height: 20px;
}

.name {
    font-family: Merriweather, sans-serif, arial;
}
