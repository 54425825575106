@import '../../../../styles/includes';

.hero {
  background-image: url('../../../../assets/images/header.jpg');

  padding: 30px 25px 30px;
  color: $white;
  font-weight: bold;
  font-size: 34px;
  line-height: 42px;
  letter-spacing: -0.34px;
  background-size: cover;

  @include screen(sm) {
    padding: 30px 25px 60px;
  }

  .hero__description {
    @include typography(p1);
    opacity: 0.7;

    a {
      color: #fff;
      text-decoration: underline;
    }
  }

  .hero__title {
    margin-bottom: 30px;

    @include typography(t1);

    strong {
      font-weight: 500;
    }
    p {
      font-weight: 100;
    }

    @include screen(sm) {
      margin-top: 20px;
      margin-bottom: 20px;
    }
  }

  & p {
    margin: 0;

    a {
      color: $santeFe;
      text-decoration: none;
    }
  }
}
