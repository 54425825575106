@import '../../../../styles/includes.scss';

.state-parties__content {
    .no-borders {
      border: 0;
      td, th {
        border: 0;
      }
    }
    .state-parties__expandable {
      display: block;
      @include screen(md) {
        display: none;
      }
      .expandable {
        margin: 0 0 15px;
        .expandable__content {
          border: 1px solid $grey;
        }
      }
      .state-parties__expandable-header {
        display: flex;
        height: 65px;
        width: 100%;
        border: 1px solid $grey;
        .state-parties__map-color {
          width: 7%;
          height: 100%;
          &.declaration90 {
            background-color: #C0CC00;
          }
          &.state-party {
            background-color: #C0CC00;
          }
          &.signature {
            background-color: #C0794E;
          }
          &.other {
            background-color: #919191;
          }
        }
        .state-parties__subtitle-content {
          display: flex;
          font: normal normal normal 14px/17px Merriweather;
          .state-parties__subtitle {
            display: flex;
            justify-content: space-between;
            div {
              padding: 0 30px 0 0;
            }
          }
        }

        .state-parties__header-content {
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          padding: 5px 0 5px 10px;
          width: 90%;
          .state-parties__country {
            color: $bostonBlue;
            text-decoration: none;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            width: 90%;
            font: normal normal bold 19px/23px Merriweather;
            letter-spacing: 0;

            &.is-inactive {
              color: $greyDark;
            }
          }
        }
      }
      .state-parties__expandable-content {
        padding: 10px;
        font: normal normal normal 14px/24px Open Sans;
        letter-spacing: -0.14px;
        div {
          width: 100%;
          display: flex;
          justify-content: space-between;
          padding: 0 50px;
          & > span:only-child {
            margin-left: auto;
            text-align: right;
            max-width: 100px;
          }
          span {
            padding: 5px 0;
            a {
              text-decoration: none;
            }
          }
        }
      }
    }
    table {
      display: none;
      @include screen(md) {
        display: block;
        width: 100%;
        border-collapse: collapse;
        thead {
          tr {
            background-color: $grey;
            th {
              height: 60px;
            }
          }
        }
        tbody {
          tr {
            border-bottom: 1px solid $grey;
            .state-parties__country {
              color: $bostonBlue;
              font-weight: bold;
              &.is-inactive {
                color: $greyDark;
              }
            }
            td {
              height: 60px;
              font: normal normal normal 15px/28px Merriweather;
              color: #1f1e1d;
              padding-left: 10px;
              padding-right: 10px;
              a {
                color: $bostonBlue;
                font-weight: bold;
                text-decoration: none;
              }
            }
          }
        }
      }
    }

    .state-parties__header-title {
      font: normal normal bold 12px/28px Merriweather;
      text-align: left;
      min-width: 230px;
      align-items: center;
      cursor: pointer;
      .state-parties__header-title-container {
        display: flex;
        .state-parties__header-title-content {
          display: flex;
          align-items: center;
        }
        a {
          color: $bostonBlue;
          text-decoration: none;
          padding-left: 5px;
        }
      }

      .title {
        display: flex;
        flex-direction: column;
      }
      .icon {
        margin-right: 10px;
        color: $bostonBlue;
      }
    }
    .state-parties__map-color {
      width: 24px;
      &.declaration90 {
        background-color: #C0CC00;
      }
      &.state-party {
        background-color: #C0CC00;
      }
      &.signature {
        background-color: #C0794E;
      }
      &.other {
        background-color: #919191;
      }
    }
    .state-parties__link {
      font: normal normal bold 14px/15px Merriweather;
      color: $bostonBlue;
      cursor: pointer;
      @include screen(md) {
        font: normal normal bold 15px/15px Merriweather;
      }
    }
    .state-parties__footnotes {
      padding-top: 20px;
      font: normal normal normal 15px/20px Open Sans;
      .state-parties__footnote-item {
        display: flex;
        padding: 20px 0;
        position: relative;
        span {
          color: $bostonBlue;
          min-width: 20px;
        }
        div {
          p {
            margin: 0;
            padding-left: 10px;
            line-height: 25px;
            text-align: justify;
            text-justify: inter-word;
          }
        }
        a {
          position: absolute;
          top: -200px;
        }
      }
    }
  }
