.wrap {
  display: block;
}

.title {
  margin: 0;
  font-size: 14px;
  padding-left: 5px;
  min-height: 18px;
  margin-bottom: 8px;
}