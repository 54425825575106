.national-practice-list__topic-subtitle {
    margin: 15px 0;
    display: flex;
    align-items: center;
    column-gap: 10px;
}

.national-practice-list__topic-count {
    font-family: Helvetica, Arial, sans-serif;
    font-size: 16px;
    font-weight: 700;
    color: #3c795a;
    cursor: pointer;
    transition: 0.5s;
}

.national-practice-list__topic-count:hover {
    opacity: 0.7;
}

.national-practice-list__topic-subtitle-container {
    border-bottom: 0.5px solid #dedbdb;
    margin-bottom: 20px;
    .national-practice-list__topic-subtitle {
        margin: 15px 20px;
    }
}
.has-parent {
    margin-left: 20px
}
@media screen and (max-width: 992px) {
    .national-practice-list__topic-subtitle {
        padding: 0px 20px;
    }
}




