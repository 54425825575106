.wrap {
    position: fixed;
    left: 0;
    top: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0,0,0, .3);
    z-index: 500;
    display: flex;
    justify-content: center;
    align-items: center;
}


.container {
    width: 600px;
    max-width: 100%;
    padding: 20px;
    background-color: lightgrey;
    position: relative;
}

.content {
    margin-top: 50px;
    display: flex;
    column-gap: 20px;
}


.close {
    position: absolute;
    right: 10px;
    top: 10px;
    font-size: 30px;
    color: #a50044;
    cursor: pointer;
}
