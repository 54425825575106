@import '../../styles/includes.scss';

.dropdown {
  display: block;
  position: relative;

  .dropdown__wrapper-dateAdopt {
    display: block;
    position: fixed;
    top: 100px;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    width: 100vw;
    height: 100vh;
    line-height: 34px;
    background-color: rgba($black, 0.5);
    padding: 10%;

    @include screen(md) {
      top: 0;
      position: relative;
      width: auto;
      height: auto;
      background-color: transparent;
      padding: 0;
      min-width: 310px;
    }

    .dropdown__container {
      position: relative;
      width: 100%;
      height: 100%;
      max-width: 100%;
      max-height: 100%;
    }

    .dropdown__content {
      position: relative;
      max-width: 100%;
      max-height: calc(100% - 60px);
      overflow-y: auto;
      overflow-x: hidden;

      @include screen(md) {
        max-height: 250px;
      }
    }
  }

  .dropdown__content {
    margin-top: 2px;
    border: 1px solid #888;
    border-radius: 4px;
    background-color: $white;
    color: $black;
    text-align: left;
    cursor: default;
    padding: 5px 10px;

    .dropdown__content-date {
      width: 100%;
      p {
        font-size: 12px;
        font-weight: bold;
        color: $black;
        height: 10px;
        margin-top: 0;
      }
      .input--date {
        input {
          padding: 0 20px 0 0;
        }
      }
      input{
        border: 1px solid $grey;
      }
    }
    .dropdown__content-order {
      border-top: 1px solid $grey;
      padding-top: 5px;
      div {
        cursor: pointer;
      }
      .dropdown__content-order--active {
        color: $black;
      }
      span {
        font-size: 12px;
        font-weight: bold;
      }
    }
  }

  .dropdown__search {
    display: block;
    margin-bottom: 10px;

    @include screen(md) {
      display: none;
    }
  }

  .dropdown-option {
    border-bottom: 1px solid lighten(#888, 40%);
    white-space: nowrap;
    padding: 6px 12px;
    cursor: pointer;

    @include screen(md) {
      padding: 0 10px;
    }

    &.dropdown-option--hovering {
      background-color: $black;
      color: $white;
    }

    &.dropdown-option--selected {
      background-color: $main;
      color: $white;
    }

    &.icon-left {
      display: flex;
      flex-direction: row-reverse;
      justify-content: flex-end;
    }

    &.icon-right {
      display: flex;
      justify-content: space-between;
    }
  }
}

.dropdown-input {
  position: relative;
  background-color: $white;
  border-radius: 4px;

  .dropdown-input__container--input {
    background-color: transparent;
    position: relative;
    padding-right: 20px;
    text-overflow: ellipsis;
    font-family: Open Sans;
  }

  .dropdown-input__container--icon {
    position: absolute;
    right: 0;
    top: 0;
  }
}
