.wrap {
  display: grid;
  grid-template-columns: 1fr 2fr;
  row-gap: 20px;
  column-gap: 20px;
  align-items: center;
}


.bottomWrap {
  display: grid;
  grid-template-columns: 4fr 2fr 1fr;
  row-gap: 20px;
  column-gap: 20px;
}

.anonymizedBlock {
    display: flex;
    align-items: center;
    column-gap: 50px;
}

.status {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    align-items: flex-start;
}

@media screen and (max-width: 880px) {
  .wrap,
  .bottomWrap {
    grid-template-columns: 1fr;
  }
}
