@import '../../../../../../styles/includes.scss';

.page-wrapper {
    margin: 0 auto;
    max-width: 1260px;
    padding: 30px 20px;

    @include screen(md) {
        padding: 30px 20px;
    }
}
