.wrap {
  display: grid;
  align-items: center;
  padding: 20px 0;
}

.title {
  background-color: transparent;
  margin: 0;
  color: #fff;
  font-size: 15px;
  font-weight: 500;
  padding: 4px;
}

.value {
  height: 50px;
  background-color: lightgrey;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 20px;
}

.valueFile {
  background-color: transparent;
  color: #fff;
}


.fileText {
  font-size: 11px;
}
