.wrap {
  display: grid;
  grid-template-columns: 80px 330px auto;
  min-height: 50px;
  position: relative;
}

.title {
  padding: 40px 20px;
  font-size: 21px;
  font-weight: 700;
  margin: 0;
  display: flex;
  align-items: flex-start;
  column-gap: 3px;
  word-break: break-word;
  z-index: 15 !important;
}
.content {
  padding: 20px;
    position: relative;
}

.disabled {
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 15;
}


.wrapGrey {
  background: #f2f2f2;
}

.wrapBlocked {
  background: grey;
}

@media screen and (max-width: 880px) {
  .wrap {
    grid-template-columns: 1fr;
  }

  .indicator {
    height: 50px;
  }
}


