
@mixin theme($name) {
  @at-root .theme--#{$name} #{&} {
    @content;
  }
}

@function createTheme($name, $color) {
  $t:(
    name: $name,
    m-color: $color,
    l-color: lighten($color, 20%),
    d-color: darken($color, 20%),
  );
  @return $t;
}

$treaties: createTheme(ihl-treaties, #E3000B);
$customary: createTheme(customary-ihl, #178ec3);
$national: createTheme(national-practice, #3C795A);
$search: createTheme(search, #2A3675);
$upload: createTheme(upload, #B2C026);