@import '../../../../../styles/includes.scss';

.national-practice__sort {
  display: flex;
  justify-content: flex-start;
  padding: 15px 0 0 20px;
  @include screen(md) {
    padding: 5px 0 15px 0;
  }

  span {
    font-size: 15px;
    display: flex;
    align-self: center;
    font-weight: bold;
    @include screen(md) {
      font-size: 12px;
    }
  }
  .input {
    width: 140px;
    font-size: 14px;
    border-radius: 0;
    border: 0;
    @include screen(md) {
      font-size: 12px;
    }

    .icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      padding-top: 2px;
      @include screen(md) {
        font-size: 16px;
      }
    }
    .dropdown__wrapper {
      top: 15%;

      @include screen(md) {
        top: 0;
      }
    }
    .dropdown__content {
      color: $greyLight;
      .dropdown-option.dropdown-option--selected {
        color: $black;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
}

.dropdown {
  z-index: 11;
  .dropdown__container {
    width: 150px;
  }
}

.national-practice-list {
  width: 100%;
  margin: 0;



  .treaty__sort {
      display: flex;
      align-items: center;
      column-gap: 10px;

      .national-practice-list-collapse {
          margin-left: 0;
          cursor: pointer;
          font-family: Merriweather, serif;
          font-weight: bold;
          font-size: 12px;
          color: #1d1e1d;
          display: flex;
          column-gap: 12px;
          align-items: center;

          @include screen(md) {
              margin-left: 20px;
          }

          .national-practice-list-collapse-icon {
              width: 19px;
              height: 19px;
              background: #62a3bb;
              border-radius: 50%;
              display: flex;
              justify-content: center;
              align-items: center;
              position: relative;

              .collapse-icon-dec,
              .collapse-icon-dec-2 {
                  width: 30%;
                  height: 1px;
                  background: #fff;
                  position: absolute;
              }

              .collapse-icon-dec-2 {
                  transform: translateY(-2px);
              }

              .collapse-icon-dec::before,
              .collapse-icon-dec::after {
                  content: '';
                  width: 140%;
                  height: 1px;
                  background: #fff;
                  position: absolute;
                  margin-left: -20%;
              }

              .collapse-icon-dec-2::after {
                content: '';
                width: 100%;
                height: 1px;
                background: #fff;
                position: absolute;
                transform: translateY(3px);
              }

              .collapse-icon-dec::before {
                  transform: translateY(-3px);
              }

              .collapse-icon-dec::after {
                  transform: translateY(3px);
              }
          }
      }
  }


  .loader {
    padding-top: 50px;
  }
  @include screen(md) {
    width: 78%;
    padding: 10px;
  }
  .national-practice-list-historical-title {
    padding: 0 20px;
    @include screen(md) {
      padding: 0;
    }
  }
  .national-practice-list__topic-title {
    @include typography(t3);
    font-size: 22px;
    padding-bottom: 15px;
    padding-top: 20px;
    @include screen(md) {
      font-size: 24px;
    }
  }
  .national-practice-list__topic-title.flex{
    align-items: center;
  }
  .national-practice-list__topic-title i {
    margin-left: 10px;
    cursor: pointer;
  }
  .national-practice-list__topic-title span {
    cursor: pointer;
  }
  .national-practice-list__sub-title-shave {
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  .national-practice-list_no_results {
    padding: 15px 20px;
    display: flex;
  }
  .national-practice-list_item {
    border-top: 0.5px solid $darkWhite;
    padding: 15px 0px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    @include screen(md) {
      flex-direction: row;
      padding: 15px 20px;
    }
    .national-practice-list_map-image {
      cursor: pointer;
      margin: 0 20px 0 0;
      width: 10%;

      .image {
        width: 31px;
        height: 30px;
        background-color: $darkWhite;
        img {
          width: 31px;
          height: 30px;
        }

        @include screen(md) {
          width: 66px;
          height: 64px;
          img {
            width: 66px;
            height: 64px;
          }
        }
      }
      .map-label {
        display: inline-block;
        font: normal normal normal 14px/19px Open Sans;
        color: $bostonBlue;
        @include screen(md) {
          display: none;
        }
      }
    }
    .national-practice-list_item-content {
      display: flex;
      flex-direction: column;

      &.noMap {
        width: 100%;
      }

      .national-practice-list_date {
        @include typography(p4);
        color: $greyLight;
      }
      .national-practice-list__sub-title {
        @include typography(p4);
        display: flex;
        align-items: center;
        color: $greyLight;
        .national-practice-list__sub-title__country {
          font-weight: bold;
          text-transform: uppercase;
        }
        .separator {
          margin: 0 5px;
        }
        .bullet {
          display: inline;
          width: 3px;
          height: 3px;
          border-radius: 20px;
          background-color: $greyLight;
        }
      }
      .national-practice-list_title {
        font-size: 13px;
        line-height: 19px;
        font-weight: 600;
        color: #62A3BB;
        padding-right: 10px;
        text-decoration: none;
        @include screen(md) {
          @include typography(t4);
        }
      }
      .national-practice-list_link {
        @include typography(p5);
        color: $bostonBlue;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .national-practice-list_item-state {
    padding: 0 15px;
    @include screen(md) {
      position: relative;
      padding: 0;
    }
    .national-practice-list_item-state-country {
      @include typography(t3);
      position: sticky;
      top: 15%;
      padding-bottom: 15px;
      padding-top: 40px;
      background-color: $white;
      z-index: 10;
    }
    .national-practice-list_item-state-topic {
      border-bottom: 1px solid $grey;
      font: normal normal bold 16px/28px Open Sans;
      letter-spacing: -0.16px;
      padding-bottom: 5px;
      padding-top: 10px;
      cursor: pointer;
    }
    .national-practice-list_item-state-national-practice-content {
      border-bottom: 1px solid $lightAlto;
      padding-bottom: 15px;
      padding-left: 15px;
      margin-bottom: 10px;
    }
    .national-practice-list_item-state-date {
      color: #6f6969;
      @include typography(p4);
    }
    .national-practice-list_title {
      @include typography(t4);
      color: $bostonBlue;
      padding-right: 10px;
      text-decoration: none;
      cursor: pointer;
    }

    .national-practice-list_item-state-state-partie {
      text-transform: uppercase;
      @include typography(p5);
      padding-bottom: 5px;
      a {
        text-decoration: none;
        color: $bostonBlue;
        display: inline-block;
      }
    }
  }
}

.national-practice-list__item-state-country-hash {
  position: absolute;
  top: -200px;
}

.national-practice__side-content {
  width: 100%;
  background-color: $grey;
  padding: 0 5%;
  @include screen(md) {
    background-color: transparent;
    width: 20%;
    padding: 0;
  }

  .share {
    margin-top: 10px;
  }

  .dropdown__wrapper {
    top: 100px;

    @include screen(md) {
      top: 0;
    }
  }
  .national-practice__main-treaties {
    background-color: $grey;
    padding: 15px 0;

    .national-practice__main-treaties-buttons {
      font: normal normal normal 12px/26px Merriweather;
      display: flex;
      justify-content: space-evenly;
      @include screen(md) {
        display: block;
      }
    }
    .value {
      font: normal 14px/28px Open Sans;
      letter-spacing: -0.28px;
      color: $black;
      padding-bottom: 10px;
      @include screen(md) {
        font: bold 14px/28px Open Sans;
        padding-bottom: 0px;
      }
    }
    a {
      display: block;
      width: 150px;
      height: 40px;
      text-align: center;
      padding: 5px;
      font: bold 15px/28px Open Sans;
      color: $white;
      background-color: $bostonBlue;
      text-decoration: none;

      @include screen(md) {
        display: inline;
        padding: 0;
        color: #428bca;
        background-color: transparent;
      }
    }
    i {
      color: $white;
      font-size: 21px;
      width: 21px;
      height: 21px;
      line-height: 21px;
      @include screen(md) {
        color: #585858;
      }
    }
    @include screen(md) {
      display: inline-block;
      border-bottom: 1px solid $lightAlto;
      background-color: transparent;
      text-align: left;
    }
  }
}
