.multi-range-slider {
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 50px;
  }
  
  .slider {
    position: relative;
    width: 100%;
  }
  
  .slider__track,
  .slider__range,
  .slider__left-value,
  .slider__right-value {
    position: absolute;
  }
  
  .slider__track,
  .slider__range {
    border-radius: 3px;
    height: 4px;
  }
  
  .slider__track {
    background-color: #ced4da;
    width: 100%;
    z-index: 1;
  }
  
  .slider__range {
    background-color: #62A3BB;
    z-index: 2;
  }
  
  .slider__left-value,
  .slider__right-value {
    color: #333333;
    font-size: 14px;
    line-height: 17px;
    margin-top: 20px;
  }
  
  .slider__left-value {
    left: 6px;
  }
  html[dir="rtl"] {
    .slider__left-value {
      left: auto;
      right: 6px;
    }
  }
  
  .slider__right-value {
    right: -4px;
  }
  html[dir="rtl"] {
    .slider__right-value {
      right: auto;
      left: -4px;
    }
  }
  
  /* Removing the default appearance */
  .thumb,
  .thumb::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  .thumb {
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
  }
  
  .thumb--left {
    z-index: 3;
    padding-right: 10px;
  }
  
  .thumb--right {
    z-index: 4;    
    padding-left: 10px;
  }

  html[dir="rtl"] {
    .thumb--left {
      z-index: 4;
      padding-right: 0px;
    }
  
    .thumb--right {
      z-index: 3;
      padding-left: 0px;
    }
  }
  
  /* For Chrome browsers */
  .thumb::-webkit-slider-thumb {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    border: 2px solid #2A3675;
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  
  /* For Firefox browsers */
  .thumb::-moz-range-thumb {
    background-color: #fff;
    border: none;
    border-radius: 50%;
    border: 2px solid #2A3675;
    cursor: pointer;
    height: 24px;
    width: 24px;
    margin-top: 4px;
    pointer-events: all;
    position: relative;
  }
  