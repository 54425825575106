.wrap {
  display: grid;
  column-gap: 20px;
  row-gap: 10px;
}


.title {
  font-size: 12px;
}

.checkbox {
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.titleError {
    color: red;
}

.titleText {
  margin: 0;
  font-size: 14px;
  padding-top: 5px;
  padding-bottom: 5px;
  color: #262626;
}
