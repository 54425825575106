@import '../../../../../../styles/includes.scss';

.customary-practice__countries-list-item {
    border-top: 1px solid #e7e7e3;
    font-family: Merriweather, serif;
    font-weight: bold;
    font-size: 15px;
    line-height: 24px;
    color: #4aa4b9;
    transition: 0.5s;
    cursor: pointer;
    display: flex;
    column-gap: 10px;
    padding: 15px;
    width: 100%;
    text-decoration: none;

	@include screen(md) {
		width: 32%;
	}

    &-active {
        width: 100% !important
    }
    h4 {
        display: flex;
        align-items: center;
        margin: 0;
    }

    &:hover {
        background: #e7e7e3;
    }
}

.customary-practice__list-item-updated {
    color: #24bc1e;
}
