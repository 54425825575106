@import '../../../../../../styles/includes.scss';

.national-practice__sidebar {
    width: calc(100% + 40px);
    margin-left: -20px;
    background-color: $grey;
    padding: 15px 24px;
    min-width: 0;

    @include screen(md) {
        background-color: transparent;
        padding: 0;
        padding-top: 30px;
        margin-left: 0;
        display: block;
        width: 100%;
    }

    @media print {
        display: block;
    }

    a {
        color: #62A3BB;
        text-decoration: none;
    }

    .dropdown__wrapper {
        top: 100px;

        @include screen(md) {
            top: 0;
        }
    }

    .national-practice__related-document {
        margin-bottom: 10px;

        span {
            display: block;
        }

        a {
            font-family: Merriweather;
            font-size: 13px;
            line-height: 19px;
            font-weight: bold;

            @include screen(md) {
                font-size: 15px;
                line-height: 24px;
            }
        }
    }

    .item {
        flex-direction: column;
        padding-bottom: 15px;
        word-break: break-word;
        display: none;

        .label {
            font-family: Merriweather, sans-serif;
            font-size: 12px;
            line-height: 28px;
            font-weight: bold;
        }
        .value {
            font-size: 12px;
            line-height: 24px;
            font-weight: normal;
            font-family: 'Open Sans', sans-serif;
            color: #6F6969;
            p {
                margin: 0;
            }
        }
        &.documents,
        &.research-guides {
            .value {
            display: flex;
            flex-direction: column;
            }
        }

        @include screen(md) {
            display: flex;
        }

        .national-practice__source-link {
            inline-size: 100%;
            overflow-wrap: break-word;
            display: block;
            margin-bottom: 10px;
            position: relative;

            &:last-child {
                margin-bottom: 0;
            }

            img {
                width: 15px;
                height: 15px;
                position: absolute;
                right: 0;
                bottom: 5px;
            }

            span {
                display: block;
                padding-right: 20px;
            }
        }

        .national-practice__call-number {
            display: block;

            span {
                margin-left: 5px;
            }
        }
    }

    .share {
        .value {
            font-size: 15px;
            line-height: 24px;
            font-family: Merriweather;
            color: #6F6969;
        }

        @include screen('custom', 'max', '992px') {
            border-top: none;
            padding: 0;
            display: flex;
            align-items: center;

            .value {
                flex: 1;
                font-weight: bold;
                color: #65686F;
            }

            .button {
                display: none;
            }
        }
    }

    .national-practice__language {
        margin-right: 5px;
    }
}
