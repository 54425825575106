@import '../../../../../../styles/includes.scss';

.treaty__side-content {
  width: 100%;
  background-color: $grey;
  padding: 0 5%;
  @include screen(md) {
    background-color: transparent;
    width: 21%;
    padding: 0;
  }

  .dropdown__wrapper {
    top: 100px;

    @include screen(md) {
      top: 0;
    }
  }
  .treaty__main-treaties {
    background-color: $grey;
    padding: 15px 0;

    .treaty__main-treaties-buttons {
      font: normal normal normal 12px/26px Merriweather;
      display: flex;
      justify-content: space-evenly;
      @include screen(md) {
        display: block;
      }
    }
    .value {
      font: normal 14px/28px Open Sans;
      letter-spacing: -0.28px;
      color: $black;
      padding-bottom: 10px;
      @include screen(md) {
        font: bold 14px/28px Open Sans;
        padding-bottom: 0px;
      }
    }
    a {
      display: block;
      width: 150px;
      height: 40px;
      text-align: center;
      padding: 5px;
      font: bold 15px/28px Open Sans;
      color: $white;
      background-color: $bostonBlue;
      text-decoration: none;

      @include screen(md) {
        display: inline;
        padding: 0;
        color: #428bca;
        background-color: transparent;
      }
    }
    i {
      color: $white;
      font-size: 21px;
      width: 21px;
      height: 21px;
      line-height: 21px;
      @include screen(md) {
        color: #585858;
      }
    }
    @include screen(md) {
      display: inline-block;
      background-color: transparent;
      text-align: left;
    }
  }
  .treaty__research-guides {
    background-color: $grey;
    padding: 15px 0;

    .value {
      font: normal 14px/28px Open Sans;
      letter-spacing: -0.28px;
      color: $black;
      padding-bottom: 10px;
      @include screen(md) {
        font: bold 14px/28px Open Sans;
        padding-bottom: 0px;
      }
    }
    .items {
      img {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    a {
      display: flex;
      flex-direction: row;
      color: $bostonBlue;
      text-decoration: none;
      align-items: center;
      font-family: 'Open Sans';
      font-weight: bold;

      @include screen(md) {
        font: bold 15px/24px Open Sans;
        padding: 2px;
      }
    }
    i {
      color: #585858;
      font-size: 21px;
      width: 21px;
      height: 21px;
      line-height: 21px;
      margin-right: 5px;
    }
    @include screen(md) {
      display: inline-block;
      background-color: transparent;
      text-align: left;
    }
  }
}

html[dir="rtl"] .treaty__research-guides {
  text-align: right !important;

  .item {
    flex-direction: row-reverse;
  }
}