@import '../../styles/includes.scss';

.main {
  min-height: calc(100vh - 260px);
  margin: 50px 0 0 0;
  position: relative;
  @include screen(md) {
    margin: 140px 0 0 0;
  }
  &.main--big {
    margin: 110px 0 0 0;
    @include screen(md) {
      margin: 200px 0 0 0 !important;
    }

  }
  .page-container {
    max-width: 1260px;
  }
}
table {
  border: 1px solid #9d9d9d;
  border-collapse: collapse;
  // width: 100%;

  td, th {
    border: 1px solid #9d9d9d;
    padding: 5px;
  }
}
img[data-entity-uuid] {
  max-width: 100%;
  height: auto;
}
.loader {
  height: 0 !important
}
.loader__content {
  background-color: rgba(255,255,255,0.8);
  padding: 22px 49px;
  position: fixed !important;
}
