@import '../../styles/includes.scss';
@import '@icrc/react-ui/scss/colors.scss';

.footer {
  width: 100%;
  height: auto;
  background-color: $greyDark;
  text-align: center;
  padding: 16px 0 16px 0;

  .footer__icons {
    display: inline-block;
    padding: 0 10px;
    .icon {
      width: 100%;
      height: 100%;
      margin-right: 20px;
      color: $white;
    }
  }

  .footer__separator {
    border: 0.5px solid $lightBlack;
    opacity: 1;
    margin: 14px 0 14px 0;

    @include screen(md) {
      margin: 14px 0 0 0;
    }
  }

  .footer__text {
    display: flex;
    flex-direction: column;

    @include screen(md) {
      flex-direction: row;
      justify-content: center;
    }

    .footer__text-content {
      display: flex;
      justify-content: center;
      align-items: center;
      @include screen(md) {
        padding-right: 14px;
      }
      .footer__text-bullet {
        @include screen(md) {
          display: flex;
          background-color: $lightGrey;
          width: 3px;
          height: 3px;
          margin-right: 14px;
          border-radius: 50%;
        }
      }

      a {
        font: normal normal normal 12px/23px Open Sans;
        letter-spacing: 0px;

        color: $lightGrey;
        opacity: 1;
        text-decoration: none;

        @include screen(md) {
          a {
            margin-right: 14px;
            font-size: 12px;
            line-height: 23px;
          }
        }
      }
    }
  }
}
