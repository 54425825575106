@import '../../../../styles/includes.scss';

.customary-practice__lp-title {
    font-size: 36px;
    line-height: 46px;
    padding-top: 30px;
    margin-bottom: 10px;
    @include screen(md) {
        font-size: 46px;
    }
}
.customary-practice__sort {
    font-size: 12px;
    line-height: 28px;
    font-weight: 600;
    .customary-practice__sort-link {
        a {
            color: #1F1E1D;
            opacity: .5;
            margin-right: 5px;
            transition: ease-in .2s;

            &:hover {
                opacity: 1;
            }
        }
    }
}
.customary-practice__collapse {
    margin-top: 10px;
    display: inline-block;
    font-size: 12px;
    line-height: 28px;
    font-weight: 600;
}

.cutomary-practice {
    .customary-practice__list {
        .customary-practice__section {
            &:first-child {
                display: none;
            }
        }
    }
}
