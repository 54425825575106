@import '../../../../../../styles/includes.scss';

 

.national-practice__content-main {
    & >div.loader{
        padding-top: 0 ;
    }
    .national-practice__content-title {
        word-break: break-word;
    }

    .national-practice__back-link {
         margin-top: 15px;

        @include screen(md) {
            display: block;
            margin-top: 30px;
            margin-bottom: 10px;
        }

        span {
            font-size: 12px;
            line-height: 24px;
            color: #6F6969;
            font-family: 'Open Sans', sans-serif;
        }

        i {
            font-size: 15px;
            line-height: 24px;
            color: #707070;
            margin-top: 4px;
        }

        a {
            font-size: 15px;
            line-height: 24px;
            color: #62A3BB;
            text-decoration: none;
            font-weight: bold;
            font-family: 'Open Sans', sans-serif;
        }
    }

    .national-practice__access-content {
        margin: 30px 0;

        .national-practice__access-content-item {
            background-color: #3C795A;
            padding: 0px 15px;
            color: #ffffff;
            font-size: 15px;
            line-height: 28px;
            margin: 0;

            span {
                &::before {
                    content: '<';
                    margin: 0 5px;
                }
            }
        }
    }

    .national-practice__country-title {
        font-size: 28px;
        line-height: 38px;
        font-weight: 400;
        margin-top: 0;
        margin-bottom: 15px;

        @include screen(md) {
            font-size: 30px;
            line-height: 35px;
        }

        a {
            text-decoration: none;
            color: #62A3BB;
        }
    }

    .flex {
        flex-wrap: wrap;
    }

    .national-practice-list__content-date {
        font-family: 'Open Sans';
        font-weight: bold;
    }

    .national-practice__practice-category {
        font-size: 14px;
        line-height: 20px;
        font-family: 'Open Sans', serif;
        margin-right: 30px;
        margin-bottom: 15px;

        a {
            color: white;
            background-color: #62A3BB;
            padding: 6px 12px;
            border-radius: 16px;
            text-decoration: none;
            display: flex;
        }
    }

    .national-practice__practice-topic {
        font-size: 14px;
        line-height: 20px;
        font-family: 'Open Sans', serif;
        margin-right: 30px;
        margin-bottom: 15px;

        a {
            color: white;
            background-color: #46846C;
            padding: 6px 12px;
            border-radius: 16px;
            text-decoration: none;
            display: flex;
        }
    }

    .national-practice__summary-text {
        p {
            font-size: 15px;
            line-height: 28px;
            color: #1F1E1D;
            text-align: justify;
            text-justify: inter-word;
        }
    }

    .national-practice__publication-data {
        display: inline;

        @include screen(md) {
            display: flex;
            flex-wrap: wrap;
        }

        p {
            font-size: 15px;
            line-height: 28px;
            color: #1F1E1D;
        }
    }

    .national-practice__publication-name {
        h2 {
            font-size: 19px;
            line-height: 23px;
            margin-right: 5px;

            @include screen(md) {
                font-size: 24px;
                line-height: 30px;
            }
        }
    }

    .national-practice__download-section {
        border-top: 1px solid rgba(112, 112, 112, .2);

        h4 {
            margin-top: 15px;
            margin-bottom: 0px;
        }
    }

    .national-practice__download-content {
        display: flex;
        align-items: flex-start;
        margin: 22px 0;
        flex-direction: column;

        @include screen(sm) {
            flex-direction: row;
            align-items: center;
        }

        .national-practice__download-document {
            margin-bottom: 15px;
            flex: 1;

            @include screen(md) {
                margin-bottom: 0;
            }

            span {
                padding: 0 37px 0 12px;
                font-size: 12px;
                line-height: 24px;
                color: #6F6969;
                font-family: 'Open Sans';
                margin: 0;
            }
        }

        .national-practice__download-remark {
            font-size: 12px;
            line-height: 24px;
            color: #6F6969;
            font-family: 'Open Sans';
            margin: 0;
            padding-right: 20px;
        }

        .national-practice__document-button {
            display: flex;
            background-color: #3C795A;
            margin-bottom: 15px;
            &.disabled{
                pointer-events: none;
                background: #d8cfcf;
            }
            @include screen(md) {
                margin-left: 20px;
            }

            span {
                color: #ffffff;
                font-size: 15px;
                line-height: 28px;
                text-decoration: none;
                padding: 5px 20px;
                display: flex;
                align-items: center;

                p {
                    margin: 0;
                }

                i {
                    margin-left: 15px;
                    font-size: 14px;
                }

                img {
                    max-width: 15px;
                    filter: brightness(0) invert(1);
                    margin-left: 15px;
                }
            }
        }
    }
}
