@import '../../../../styles/includes.scss';

.state-parties-detail {
    padding-top: 20px;

    h1 {
        font-size: 24px;
    }
    .loader {
        padding-top: 40px;
    }
    .state-parties-detail__container {
        display: flex;
        flex-direction: column;
        padding: 0 15px;
        @include screen(md) {
            flex-direction: row;
            justify-content: space-between;
        }
        .state-parties-detail__content {
            width: 100%;
            @include screen(md) {
                width: 70%;
            }
            .state-parties-detail__back {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                font: normal normal normal 18px/28px Open Sans;
                letter-spacing: -0.18px;
                a {
                    color: $bostonBlue;
                    @include typography(t4);
                    cursor: pointer;
                    text-decoration: none;
                }
            }

            .state-parties-detail__text{
                font-size: 15px;
                font-weight: normal;
                line-height: 28px;
                letter-spacing: 0px;
                p{
                    text-align: justify; 
                }
            }
        }
        .state-parties-detail__sharing {
            width: 100%;
            @include screen(md) {
                width: 21%;
            }
            .value {
                padding-top: 15px;
                font: normal normal normal 14px/28px Open Sans;
                letter-spacing: -0.28px;
                color: $black;
            }
            a,
            button {
                padding: 0 7.5px;
            }
            i {
                color: #585858;
                font-size: 21px;
                width: 21px;
                height: 21px;
                line-height: 21px;
            }
        }

        .state-parties-detail__declaration {
            padding: 15px 0 20px;

            .state-parties-detail__declaration-title{
                padding: 15px 0;
                text-transform: capitalize;
                font-weight: bold;
            }

            .state-parties-detail__declaration-content{
                color: $black;
                opacity: 0.7;
            }

            .state-parties-detail__declaration-content__withdrawal {
                padding-top: 5px;
            }
        }
    }
}
