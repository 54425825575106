.wrap {
  width: 35px;
  border-bottom: 12px solid #62A3BB;
  margin: 0 auto;
  height: 35px;
  position: relative;
}


.dec {
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translateX(-50%);
  bottom: -8px;
  background: #f2f7f7;
}

.line {
  position: absolute;
  height: 100%;
  width: 3px;
  background: #62A3BB;
  left: 50%;
  transform: translate(-50%, 5px);
  border-top-left-radius: 50%;
  border-top-right-radius: 50%;
}

.line:before,
.line:after {
  content: '';
  position: absolute;
  width: 3px;
  height: 12px;
}

.line:before{
  background: #62A3BB;
  transform: translate(-6px, -1px) rotate(45deg);
}

.line:after{
  background: #62A3BB;
  transform: translate(3px, -1px) rotate(-45deg);
}