@import '@icrc/react-ui/scss/colors.scss';
@import '../../../../styles/includes.scss';

.main-menu {
  position: relative;
  color: $white;

  .page-container {
    height: 50px;
    @include screen(md) {
      display: flex;
      justify-content: space-between;
    }
  }

  .top-menu__citation,
  .top-menu__credits {
    color: white;
    text-decoration: none;
    font-size: 12px;
    margin-right: 10px;
    cursor: pointer;
  }

  .top-menu__field-button {
    margin-bottom: 8px;
    cursor: pointer;
  }

  .main-menu__mobile {
    width: 100%;
    display: inline-block;
    @include screen(md) {
      display: none;
    }
  }

  .main-menu__desktop {
    display: none;
    position: relative;
    @include screen(md) {
      display: block;
      .menu {
        background-color: $greyDark;
        .page-container {
          max-width: 1260px;
        }
      }
      .content {
        display: none;
        background-color: $white;
        height: 60px;
        &.main-menu__item--active {
          display: block;
        }

        .columns.columns--gap-1 {
          margin-left: 0px;
          margin-right: 0px;
        }

        .sub-menu {
          width: 100%;
          column-gap: 10px;

          .sub-menu__sub-item-divider {
            background-color: #f5f5f5;
            height: 60px;
          }

          .column {
            padding-left: 0;
            padding-right: 0;
            flex: 1;
          }
        }
      }
    }
  }

  .main-menu__actions {
    background-color: $greyDark;
    @include theme(map-get($treaties, name)) {
      background-color: map-get($treaties, m-color);
    }
    @include theme(map-get($upload, name)) {
      background-color: map-get($upload, m-color);
    }
    @include theme(map-get($customary, name)) {
      background-color: map-get($customary, m-color);
    }
    @include theme(map-get($national, name)) {
      background-color: map-get($national, m-color);
    }
    @include theme(map-get($search, name)) {
      background-color: map-get($search, m-color);
    }
    .action {
      width: 48px;
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        color: $white;
      }

      &.active {
        background-color: #504d4b;
      }
    }
    .logo {
      background-color: $white;
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        height: 40px;
      }
    }
    .actions-content {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: 10px;
      .icon {
        margin: 0 7px;
      }
      a {
        .icon {
          color: $white;
          vertical-align: super;
        }
      }
      .top-menu__language {
        margin-left: 25px;
        .language-selector {
          color: $white;
          background-color: $greyDark;
          @include theme(map-get($treaties, name)) {
            background-color: map-get($treaties, m-color);
          }
          @include theme(map-get($upload, name)) {
            background-color: map-get($upload, m-color);
          }
          @include theme(map-get($customary, name)) {
            background-color: map-get($customary, m-color);
          }
          @include theme(map-get($national, name)) {
            background-color: map-get($national, m-color);
          }
          @include theme(map-get($search, name)) {
            background-color: map-get($search, m-color);
          }
        }
      }
    }
  }

  .main-menu__item {
    position: relative;
    z-index: 6;
    width: 100%;
    font-size: 14px;
    letter-spacing: -0.21px;
    text-transform: uppercase;
    overflow-y: scroll;
    overflow-x: hidden;
    max-height: calc(100vh - 120px);

    a {
      text-decoration: none;
    }
    @include screen(md) {
      width: 100%;
      display: inline-block;
      overflow: unset;
    }
    .main-menu__header {
      @include typography(t7);
      margin: auto;
      background-color: $greyDark;
      border: 1px solid $lightBlack;
      height: 50px;
      padding: 0 15px;
      display: flex;
      align-items: center;
      color: $white;

      @include screen(md) {
        justify-content: center;
        &.search {
          color: #b2b2b2;
          text-transform: uppercase;
          .icon {
            padding-left: 10px;
          }
        }
        &:hover {
          background-color: $lightBlack;
          cursor: pointer;
        }
      }
      &.main-menu__item--active {
        @include theme(map-get($treaties, name)) {
          background-color: map-get($treaties, m-color);
        }
        @include theme(map-get($upload, name)) {
          background-color: map-get($upload, m-color);
        }
        @include theme(map-get($customary, name)) {
          background-color: map-get($customary, m-color);
        }
        @include theme(map-get($national, name)) {
          background-color: map-get($national, m-color);
        }
        @include theme(map-get($search, name)) {
          background-color: map-get($search, m-color);
        }
      }
      &.home-active {
        background-color: $white;
        color: $black;
      }
    }
  }

  .sub-menu__container {
    background-color: $white;
  }
  .sub-menu {
    display: none;
    background-color: $white;
    height: 60px;
    align-items: center;
    @include screen(md) {
      width: 100%;
    }

    &.main-menu__item--active {
      display: flex;
      flex-wrap: nowrap;
      overflow: auto;

      .item {
        min-width: 240px;
        height: 100%;
        margin: 0 5px;
        @include screen(md) {
          margin: 0;
        }
      }
    }

    .sub-menu__sub-item-divider {
      @include typography(t8);
      font-size: 10px;
      text-align: center;
      text-transform: uppercase;
      color: $black;
      justify-content: center;
      align-items: center;
      display: flex;
      height: 100%;
      text-decoration: none;
      background-color: #f5f5f5;
      display: flex;
      flex-direction: column;

      .subItem-subTitle {
        font: normal normal normal 10px/16px Open Sans;
        text-overflow: ellipsis;
        overflow: hidden;
        width: 99%;
        white-space: nowrap;
        @include screen(md) {
          font: normal normal normal 12px/24px Open Sans;
        }
      }

      &.main-menu__item--active {
        &.column__item--bg-0 {
          @include theme(map-get($treaties, name)) {
            background-color: map-get($treaties, d-color);
            color: $white;
          }
          @include theme(map-get($upload, name)) {
            background-color: map-get($upload, d-color);
            color: $white;
          }
          @include theme(map-get($customary, name)) {
            background-color: map-get($customary, d-color);
            color: $white;
          }
          @include theme(map-get($national, name)) {
            background-color: map-get($national, d-color);
            color: $white;
          }
          @include theme(map-get($search, name)) {
            background-color: map-get($search, d-color);
            color: $white;
          }
        }
        &.column__item--bg-1 {
          @include theme(map-get($treaties, name)) {
            background-color: map-get($treaties, m-color);
            color: $white;
          }
          @include theme(map-get($upload, name)) {
            background-color: map-get($upload, m-color);
            color: $white;
          }
          @include theme(map-get($customary, name)) {
            background-color: map-get($customary, m-color);
            color: $white;
          }
          @include theme(map-get($national, name)) {
            background-color: map-get($national, m-color);
            color: $white;
          }
          @include theme(map-get($search, name)) {
            background-color: map-get($search, d-color);
            color: $white;
          }
        }
        &.column__item--bg-2 {
          @include theme(map-get($treaties, name)) {
            background-color: map-get($treaties, l-color);
            color: $white;
          }
          @include theme(map-get($upload, name)) {
            background-color: map-get($upload, l-color);
            color: $white;
          }
          @include theme(map-get($customary, name)) {
            background-color: map-get($customary, l-color);
            color: $white;
          }
          @include theme(map-get($national, name)) {
            background-color: map-get($national, l-color);
            color: $white;
          }
          @include theme(map-get($search, name)) {
            background-color: map-get($search, d-color);
            color: $white;
          }
        }
      }

      &.column__item--bg-0 {
        @include theme(map-get($treaties, name)) {
          border-bottom: 7px solid map-get($treaties, d-color);
        }
        @include theme(map-get($upload, name)) {
          border-bottom: 7px solid map-get($upload, d-color);
        }
        @include theme(map-get($customary, name)) {
          border-bottom: 7px solid map-get($customary, d-color);
        }
        @include theme(map-get($national, name)) {
          border-bottom: 7px solid map-get($national, d-color);
        }
        @include theme(map-get($search, name)) {
          border-bottom: 7px solid map-get($search, d-color);
        }
      }
      &.column__item--bg-1 {
        @include theme(map-get($treaties, name)) {
          border-bottom: 7px solid map-get($treaties, m-color);
        }
        @include theme(map-get($upload, name)) {
          border-bottom: 7px solid map-get($upload, m-color);
        }
        @include theme(map-get($customary, name)) {
          border-bottom: 7px solid map-get($customary, m-color);
        }
        @include theme(map-get($national, name)) {
          border-bottom: 7px solid map-get($national, m-color);
        }
        @include theme(map-get($search, name)) {
          border-bottom: 7px solid map-get($search, m-color);
        }
      }
      &.column__item--bg-2 {
        @include theme(map-get($treaties, name)) {
          border-bottom: 7px solid map-get($treaties, l-color);
        }
        @include theme(map-get($upload, name)) {
          border-bottom: 7px solid map-get($upload, l-color);
        }
        @include theme(map-get($customary, name)) {
          border-bottom: 7px solid map-get($customary, l-color);
        }
        @include theme(map-get($national, name)) {
          border-bottom: 7px solid map-get($national, l-color);
        }
        @include theme(map-get($search, name)) {
          border-bottom: 7px solid map-get($search, l-color);
        }
      }
    }
    .sub-menu__sub-item {
      padding-top: 10px;
      font-size: 10px;
      text-transform: none;
      letter-spacing: 0.14px;
      a {
        color: $white;
        text-decoration: none;
      }
      @include screen(md) {
        font-size: 12px;
        a {
          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }
    }
  }
}
