
.wrap {
    position: relative;
    width: 100%;
}

.value {
    box-sizing: border-box;
    width: 100%;
    height: 38px;
    align-items: center;
    display: grid;
    grid-template-columns: 1fr 20px;
    background: #fff;
    border: 1px solid rgb(204, 204, 204) !important;
    column-gap: 5px;
    padding: 4px;
}

.valueActive {
    grid-template-columns: auto auto 20px;
}

.value::-webkit-scrollbar {
    display: none;
}

.input {
    height: 28px;
    width: 100%;
    border: none !important;
    outline: none !important;
    padding: 0 8px;
}

.valueLabel {
    font-family: 'Merriweather', sans-serif;
    font-size: 12px;
    width: max-content;
    color: #262626;
}

.inputWrap {
    position: relative;
}


.icon {
    cursor: pointer;
    color: #000;
}


.iconOpen {
    cursor: pointer;
    color: #62a3bb;
    font-weight: 700;
    scale: 1.6;
    z-index: 0;
}

.list {
    position: absolute;
    background: #fff;
    height: auto;
    max-height: 350px;
    overflow-y: scroll;
    z-index: 11;
    width: 100%;
    padding: 4px;
    margin-top: 5px;
    border: 0.5px solid silver;
    font-family: Merriweather, sans-serif, arial;
    color: grey;
    font-size: 14px;
}

.list::-webkit-scrollbar {
    display: none;
}
