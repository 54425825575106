@import '../../../../styles/includes.scss';

.customary-practice__states-list {
    .customary-practice__states-list-intro {
        margin: 30px 0;
        font-size: 12px;
        line-height: 18px;
        color: #6F6969;
        font-family: 'Open Sans', serif;
    }

    .customary-practice__states-updated-body {
        margin-top: 50px;
        column-count: 3;
        justify-content: space-between;

        .customary-practice__countries-list-item {
          width: 100%;
        }
    }

    .list-filters-label {
      background-color: #F8F8F8;
      padding: 20px 24px;
      margin-left: -20px;
      width: calc(100% + 40px);
    }

    .customary-practice__details-filter {
        background-color: #F8F8F8;
        font-family: "Open Sans";
        font-size: 12px;
        line-height: 28px;
        display: none;

        @include screen(md) {
          padding: 20px;
          margin-bottom: 20px;
          display: grid;
          grid-template-columns: 400px auto;
        }

        &.active {
          display: flex;
          flex-direction: column;
          padding: 20px 24px;
          margin-left: -24px;
          width: calc(100% + 48px);
          margin-bottom: 30px;

          input {
            margin-right: 0;
            margin-bottom: 20px;
          }

          .customary-practice__filter-letters {
            flex-wrap: wrap;
          }
        }

        label {
            display: inline-block;
          }

          input {
            margin-top: 5px;
            margin-right: 15px;
            border-radius: 0;
            background-color: #fff;
            width: 100%;
            border: 1px solid #DBDBDB;
            border-radius: 5px;
            padding: 15px;
            @include screen(md) {
              width: 360px;
            }
          }

          .react-select-container {
            width: 290px;
          }

          span {
            font-size: 12px;
            font-weight: 600;
            display: block;
          }

          .customary-practice__filter-letters {
            display: flex;
            column-gap: 15px;
            text-transform: uppercase;
            color: #62A3BB;
            margin-top: 12px;

            span {
              color: #62A3BB;
              text-transform: uppercase;
              cursor: pointer;
            }

            .list-customary-active-letter {
                text-decoration: underline;
                font-weight: bold;
          }
        }
    }
}
