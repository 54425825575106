@import '../../../../../../styles/includes.scss';

.customary-practice__update-item {
  border-top: 1px solid rgba(112, 112, 112, 0.1);
  padding: 15px 0;
  font-size: 15px;
  line-height: 24px;
  cursor: pointer;

    .expandable__header {
      padding-right: 30px;
    }

    .customary-practice__update-item-text p {
      color: #1F1E1D;
      font-family: Merriweather;
      line-height: 24px;
      margin-bottom: 0;
    }
}