@import '../../styles/includes.scss';

.article-view {
  padding-top: 30px;
  h1 {
    font-size: 24px;
  }
  .article-view__content {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    @include screen(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    .article-view__content-main {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      line-height: 35px;

      @include screen(md) {
        width: 60%;
        padding-right: 24px;
      }

      .article-view__back {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font: normal normal normal 18px/28px Open Sans;
        letter-spacing: -0.18px;
        a {
          color: $bostonBlue;
          @include typography(t4);
          cursor: pointer;
          text-decoration: none;
        }
      }
      .article-view__description {
        text-align: justify;
        text-justify: inter-word;
        @include typography(p2);
      }
    }
    .article-view__content-side {
      width: 100%;
      @include screen(md) {
        width: 32%;
      }
      .article-view__navigation {
        display: none;
        @include screen(md) {
          display: block;
          height: 550px;
          overflow-y: scroll;
          margin-bottom: 30px;
          border: 1px solid #fafafa;
          background-color: #fafafa;
        }
      }
    }
    .article-float {
      display: block;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      height: 112px;
      .article-float__actual {
        @include typography(t7);
        font-weight: 600;
        line-height: 25px;
        text-transform: uppercase;
        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        color: $white;
        @include theme(map-get($treaties, name)) {
          background-color: map-get($treaties, m-color);
        }
        @include theme(map-get($customary, name)) {
          background-color: map-get($customary, m-color);
        }
        @include theme(map-get($national, name)) {
          background-color: map-get($national, m-color);
        }
        div {
          padding-right: 5px;
        }
      }
      .article-float__navigation {
        @include typography(p4);
        background-color: white;
        color: #6f6969;
        height: 56px;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        align-items: center;
      }
      @include screen(md) {
        display: none;
      }
    }
    .article-float__expanded-content {
      display: block;
      height: 100%;
      position: fixed;
      z-index: 20;
      background-color: white;
      top: 50px;
      right: 0;
      left: 0;
      overflow-y: scroll;
      padding-bottom: 50px;
      .article-float__actual {
        @include typography(t7);
        font-weight: 600;
        line-height: 25px;
        text-transform: uppercase;
        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        color: $white;
        @include theme(map-get($treaties, name)) {
          background-color: map-get($treaties, m-color);
        }
        @include theme(map-get($customary, name)) {
          background-color: map-get($customary, m-color);
        }
        @include theme(map-get($national, name)) {
          background-color: map-get($national, m-color);
        }
        div {
          padding-right: 5px;
        }
      }
      @include screen(md) {
        display: none;
      }
    }
  }  
  .article-view__navigation-item {
    display: flex;
    flex-direction: column;
    height: auto;
    border-bottom: 1px solid $lightAlto;
    padding: 10px 20px;
    a {
      color: $bostonBlue;
      text-decoration: none;
      width: 100%;
      &.blocked{
        color: #65686f
      }
      .line {
        .description {
          white-space: nowrap;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      div {
        padding-right: 5px;
      }
    }

    &.active {
      background-color: #62a3bb;
      a {
        color: $white;
      }

      .triangle {
        float: left;
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-left: 6px solid $white;
        padding-right: 20px;
        margin-top: 8px;
      }
    }

    .article-view__navigation-item-title {
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px 0;

      @include screen(md) {
        white-space: nowrap;
      }
    }

    .article-view__navigation-item-commentary {
      font-size: 14px;
      font-weight: normal;
      padding: 5px;

      .item {
        font-weight: bold;
      }
    }
  }
}
