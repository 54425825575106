.cihl-sidebar {
  width: 300px;
  padding-top: 30px;

  a:first-of-type {
    border-top: 1px solid #eee;
  }

  a {
    display: block;
    text-decoration: none;
    color: #62A3BB;
    font-size: 16px;
    font-weight: bold;
    border-bottom: 1px solid #eee;
    padding: 12px 0;

    &.active {
      background-color: #F4F4F4;
    }

    span {
      display: inline-block;
      margin-right: 10px;
    }
  }

  a.child-link {
    border-bottom: 0;
    background-color: #F4F4F4;
    padding: 5px 10px 5px 20px;
    font-size: 14px
  }
}