@import '../../../../styles/includes';

.carousel-container {
  padding: 16px;
  @include screen(sm) {
  position: relative;
  padding: 30px 0 0 0;
  }
  .carousel-hero {
    background-image: url('../../../../assets/images/header.jpg');

    min-height: 222px;
    min-width: 100%;
    color: white;
    font-weight: bold;
    font-size: 34px;
    line-height: 42px;
    letter-spacing: -0.34px;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;

    @include screen(sm) {
      min-height: 471px;
    }

    .carousel-hero__link {
      text-decoration: none;
      color: white;
      margin-top: auto;
      font-size: 24px;
      line-height: 24px;
      font-weight: normal;
      padding: 50px 25px;
      background: rgba(0, 0, 0, 0.3);
    }

    .carousel-hero__description {
      font-style: italic;
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.14px;
      font-weight: normal;
      opacity: 0.7;
    }

    .carousel-hero__title {
      margin-top: 10px;
      margin-bottom: 20px;

      font-size: 28px;
      line-height: 36px;
      letter-spacing: -0.28px;
      font-weight: normal;

      strong {
        font-weight: 500;
      }
      p {
        font-weight: 100;
      }

      @include screen(sm) {
        margin-top: 20px;
        margin-bottom: 20px;
        font-size: 44px;
        line-height: 50px;
        letter-spacing: -0.44px;
      }
    }

    & p {
      margin: 0;

      a {
        color: $santeFe;
        text-decoration: none;
      }
    }
  }
}
