@import '../../../../../styles/includes.scss';

.search-results {

    .search-results__search-header {
        width: 100%;
        padding-top: 50px;

        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 420px;
            background-color: #F0EFEF;
            left: 0;
            top: 0;
            z-index: -1;

            @include screen(md) {
                height: 234px;
            }
        }

        h1 {
            font-size: 44px;
            line-height: 48px;
            margin-top: 0;
            margin-bottom: 30px;
            word-break: break-all;         

            @include screen(md) {
                text-align: center;
            }
        }

        .search-filters__section-title {
            display: none;
        }

        .list-filter p {
            display: none;
        }

        .search-results__header-fields{
            display: flex;
            flex-wrap: wrap;

            .search-filters {
                flex: 1;
                padding-bottom: 0;
            }

            .search-results__header-button {
                width: 100%;
                margin: 10px 0;

                @include screen(md) {
                    width: 25%;
                }

                .search-button {
                    background-color: #76A5B9;
                    text-decoration: none;
                    padding: 15px 30px;
                    color: #ffffff;
                    font-size: 13px;
                    line-height: 16px;
                    max-width: 140px;

                    .icon {
                        font-size: 17px;
                        line-height: 17px;
                        width: 17px;
                        height: 17px;
                        margin-left: 15px;
                    }
                }
            }
        }
    }

    .expandable__header {
        font-size: 26px;
        line-height: 46px;
        color: #252525;
        font-weight: bold;
        padding-left: 40px;
        cursor: pointer;
    }

    .expandable__header-btn {
        left: 0;
        right: initial;

        i {
            color: #2A3675;
            font-size: 38px;
            line-height: 38px;
            width: 38px;
            height: 38px;
            margin-left: -15px;
        }
    }

    .search-results__search-content {
        width: 100%;
    
        @include screen(md) {
            width: 75%;
            padding-left: 80px;
        }
    }
    
    .search-results__content {
        display: flex;
        flex-wrap: wrap;
        margin-top: 60px;
    }
}

.search-results__sidebar {
    width: 100%;

    @include screen(md) {
        width: 25%;
    }

    .validate-button {
        background-color: #62A3BB;
        color: #fff;
        font-size: 13px;
        line-height: 16px;
        padding: 20px 30px;
        text-align: center;
        margin-bottom: 15px;
        cursor: pointer;

        &-clear {
            background-color: rgba(98, 163, 187, 0.66); 
        }

        .icon {
            margin-left: 15px;
            font-size: 17px;
            line-height: 17px;
            width: 17px;
            height: 17px;
        }
    }
}

html[dir="rtl"] {
    .search-results__search-content {
        @include screen(md) {
            padding-left: 0;
            padding-right: 80px;
        }
    }

    .search-results__header-button {
        .search-button {
            @include screen(md) {
                float: left;
            }
        }    
    }
    .search-database__dropdown-items {
        padding: 10px 13px 10px 0!important;
    }
}
