@import '../../../../../../styles/includes.scss';

.treaty__sort {
  display: flex;
  justify-content: flex-start;
  padding: 15px 0 0 0px;
  @include screen(md) {
    padding: 5px 0 15px 0;
  }


  span {
    font-size: 15px;
    display: flex;
    align-self: center;
    font-weight: bold;
    @include screen(md) {
      font-size: 12px;
    }
  }
  .input {
    width: 140px;
    font-size: 14px;
    border-radius: 0;
    border: 0;
    @include screen(md) {
      font-size: 12px;
    }

    .icon {
      font-size: 20px;
      width: 20px;
      height: 20px;
      line-height: 20px;
      padding-top: 2px;
      @include screen(md) {
        font-size: 16px;
      }
    }
    .dropdown__wrapper {
      top: 15%;

      @include screen(md) {
        top: 0;
      }
    }
    .dropdown__content {
      color: $greyLight;
      .dropdown-option.dropdown-option--selected {
        color: $black;
        font-weight: bold;
        background-color: transparent;
      }
    }
  }
}

.dropdown {
  z-index: 11;
  .dropdown__container {
    width: 150px;
  }
}

.treaty-list {
  width: 100%;
  margin: 0;
  .loader {
    padding-top: 50px;
  }
  @include screen(md) {
    width: 70%;
  }
  .treaty-list-historical-title {
    padding: 0 20px;
    @include screen(md) {
      padding: 0;
    }
  }
  .treaty-list__topic-title {
    @include typography(t3);
    font-size: 22px;
    padding-bottom: 15px;
    padding-top: 20px;
    padding-left: 10px;
    @include screen(md) {
      font-size: 24px;
      padding-left: 0;
    }
  }
  .treaty-list_no_results {
    padding: 15px 20px;
    display: block;
    padding-left: 50px;
  }
  .treaty-list_item {
    border-top: 0.5px solid $darkWhite;
    padding: 15px 20px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
    @include screen(md) {
      flex-direction: row;
    }
    .treaty-list_map-image {
      cursor: pointer;
      margin: 0 20px 0 0;
      width: 10%;

      .image {
        width: 31px;
        height: 30px;
        background-color: $darkWhite;
        img {
          width: 31px;
          height: 30px;
        }

        @include screen(md) {
          width: 66px;
          height: 64px;
          img {
            width: 66px;
            height: 64px;
          }
        }
      }
      .map-label {
        display: inline-block;
        font: normal normal normal 14px/19px Open Sans;
        color: $bostonBlue;
        @include screen(md) {
          display: none;
        }
      }
    }
    .treaty-list_item-content {
      width: 90%;

      &.noMap {
        width: 100%;
      }

      .treaty-list_date {
        @include typography(p4);
        color: $greyLight;
      }
      .treaty-list__sub-title {
        @include typography(p4);
        display: flex;
        align-items: center;
        color: $greyLight;
        .bullet {
          display: inline;
          width: 3px;
          height: 3px;
          border-radius: 20px;
          background-color: $greyLight;
          margin: 0 5px;
          @include screen(md) {
            margin: 0 20px;
          }
        }
      }
      .treaty-list_title {
        @include typography(t4);
        color: $bostonBlue;
        padding-right: 10px;
        text-decoration: none;
      }
      .treaty-list_link {
        @include typography(p5);
        color: $bostonBlue;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        cursor: pointer;
        text-decoration: none;
      }
    }
  }

  .treaty-list_item-state {
    padding: 0 15px;
    @include screen(md) {
      position: relative;
      padding: 0;
    }
    .treaty-list_item-state-country {
      @include typography(t3);
      position: sticky;
      top: 15%;
      padding-bottom: 15px;
      padding-top: 40px;
      background-color: $white;
      z-index: 10;
    }
    .treaty-list_item-state-topic {
      border-bottom: 1px solid $grey;
      font: normal normal bold 16px/28px Open Sans;
      letter-spacing: -0.16px;
      padding-bottom: 5px;
      padding-top: 10px;
      cursor: pointer;
    }
    .treaty-list_item-state-treaty-content {
      border-bottom: 1px solid $lightAlto;
      padding-bottom: 15px;
      padding-left: 15px;
      margin-bottom: 10px;
    }
    .treaty-list_item-state-date {
      color: #6f6969;
      @include typography(p4);
    }
    .treaty-list_title {
      @include typography(t4);
      color: $bostonBlue;
      padding-right: 10px;
      text-decoration: none;
      cursor: pointer;
    }

    .treaty-list_item-state-state-partie {
      text-transform: uppercase;
      @include typography(p5);
      padding-bottom: 5px;
      a {
        text-decoration: none;
        color: $bostonBlue;
        display: inline-block;
      }
    }
  }
}

.treaty-list__item-state-country-hash {
  position: absolute;
  top: -200px;
}

.treaty__side-content {
  width: 100%;
  background-color: $grey;
  padding: 0 5%;
  @include screen(md) {
    background-color: transparent;
    width: 21%;
    padding: 0;
  }

  .dropdown__wrapper {
    top: 100px;

    @include screen(md) {
      top: 0;
    }
  }
  .treaty__main-treaties {
    background-color: $grey;
    padding: 15px 0;

    .treaty__main-treaties-buttons {
      font: normal normal normal 12px/26px Merriweather;
      display: flex;
      justify-content: space-evenly;
      @include screen(md) {
        display: block;
      }
    }
    .value {
      font: normal 14px/28px Open Sans;
      letter-spacing: -0.28px;
      color: $black;
      padding-bottom: 10px;
      @include screen(md) {
        font: bold 14px/28px Open Sans;
        padding-bottom: 0px;
      }
    }
    a {
      display: block;
      width: 150px;
      height: 40px;
      text-align: center;
      padding: 5px;
      font: bold 15px/28px Open Sans;
      color: $white;
      background-color: $bostonBlue;
      text-decoration: none;

      @include screen(md) {
        display: inline;
        padding: 0;
        color: #428bca;
        background-color: transparent;
      }
    }
    i {
      color: $white;
      font-size: 21px;
      width: 21px;
      height: 21px;
      line-height: 21px;
      @include screen(md) {
        color: #585858;
      }
    }
    @include screen(md) {
      display: inline-block;
      background-color: transparent;
      text-align: left;
    }
  }
}
