.fixed-overlay {
  position: fixed;
  top: 0;
  display: flex;
  flex-direction: column;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0,0,0,0.3);
  z-index: 100;
  .consent-container {
    max-width: 1260px;
    margin: 0 auto;
    width: 100%;
    margin-bottom: 5px;
    position: relative;
    flex: 1;
    color: #00b1ff;
    font-weight: bold;
    font-size: 22px;
    div.text {
      position: absolute;
      bottom: 0;
      width: 100%;
    }
    @media screen and (max-width: 1260px) {
      padding: 10px
    }
  }
  .gdpr-container {
    flex: 0;
    background-color: #808080;
    padding: 15px;
    box-shadow: 0 -1px 3px #6b6b6b;
    .button {
      background-color: #8A0124;
      margin: 0 5px;
      border: 2px solid #8A0124;
      color: #fff;
      text-transform: uppercase;
    }
    .cancel-button {
      background-color: transparent;
      color: #8A0124;
    }
  }
  p {
    margin: 0;
  }
  .flex {
    display: flex;
    max-width: 1260px;
    margin: 0 auto;
    align-items: center;
  }
  .flex-1 {
    flex: 1
  }
  .flex-0 {
    flex: 0
  }
}