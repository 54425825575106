@import '../../../../styles/includes.scss';

.contact-form {
    max-width: 386px;
    margin: auto;
    color: #000;

    .h-captcha-container {
        text-align: center;
        margin-bottom: 20px;
    }

    button[disabled] {
        opacity: 0.5;
        cursor: default;
    }
    .contact-form__field {
        margin: 15px 0;
        

        p {
            font: normal normal bold 12px/28px Merriweather;
            letter-spacing: 0px;
            color: #1F1E1D;
            margin: 0;
        }

        .input {
            border: none;
            margin-bottom: 15px;

            .error {
                border-color: red;
            }
        }

        input {
            border: 1px solid #DBDBDB;
            border-radius: 5px;

            &::placeholder {
                font-size: 14px;
                line-height: 19px;
                font-family: 'Open Sans', sans-serif;
                letter-spacing: -0.14px;
                color: #333333;
                opacity: 0.5;
            }
        }

        textarea {
            border: 1px solid #DBDBDB;
            border-radius: 5px;
            min-height: 135px!important;
        }

        input[type=checkbox] {
            border-radius: 0!important;
            border-color: #1F1E1D;
        }

        .input--required-unfilled::after {
            background-color: transparent;
        }
    }

    .contact-form__field--submit {
        text-align: center;

        .button--submit-button {
            border-radius: 0;
            background-color: #62A3BB;
            color: #ffffff;
            font: normal normal bold 15px/28px Merriweather;
            letter-spacing: 0px;
            padding: 15px 30px;
        }
    }
}
.contact-form__page-title {
    word-break: break-word;
}
.contact-form-success {
    div {
        text-align: right;
        margin-top: 20px;
    }
}
.contact-form-error {
    color: red;
    button:last-of-type{
        background-color: rgb(98, 163, 187);
        color: white;
        margin-left: 10px;
    }
    div {
        text-align: right;
        margin-top: 20px;
    }
}