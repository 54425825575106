@import '../../../../styles/includes.scss';

.user-login {
    text-align: center;
    padding-top: 100px;

    .custom-button {
        background-color: rgb(98, 163, 187);
        ;
    }

    .login-error {
        color: red;
        border: 3px solid red;
        padding: 10px;
        width: 400px;
        margin: 15px auto;
    }
}