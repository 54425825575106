@import '../../../../../../styles/includes';

.caroussel {
  max-width: 1280px;
  padding-bottom: 10px;
  .carousel__content {
    overflow: scroll;
    overflow-y: hidden;
    overflow-x: hidden;
    flex-wrap: nowrap;
    flex-direction: row;
  }
}
.carousel__action {
  @include screen(sm) {
    position: absolute;
    bottom: 0;
    right: 50%;
    transform: translateX(50%);
  }

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  .item {
    @include screen(sm) {
      width: 31px;
      height: 6px;
      margin: 0 10px;
      background-color: $white;
    }
    cursor: pointer;
    width: 49px;
    height: 5px;
    background-color: grey;
    margin: 0 5px;
    &.active {
      height: 14px;
      @include screen(sm) {
        height: 12px;
      }
    }
  }
}
