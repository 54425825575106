@import "includes";

.button {
  &.button--secondary {
    color: $cinereous;
    background-color: $white;
    border: solid 2px $grey;
    border-radius: 4px;
    font-size: 10px;
    font-weight: bold;
    letter-spacing: 1.16px;
    text-transform: uppercase;

    &:hover {
      color: $santeFe;
      background-color: $greyDarker;
    }
  }
}
