@import '../../../../../../styles/includes.scss';

.customary-practice__rules-side-navigation {
    display: none;
    @include screen(md) {
        display: block;
        border-top: 1px solid rgba(112, 112, 112, 0.2);
        padding-top: 20px;
        margin-top: 30px; 
        max-height: 50vh;
        overflow: auto;
    } 

    .customary-practice__navigation-item {
        margin-bottom: 18px;
    }
}

.customary-practice__sidebar-floating {
    @include screen(md) {
        position: sticky;
        top: 220px;
        z-index: 1;
    }

    .customary-practice__related-button,
    .customary-practice__related-title {
        display: none!important;

        @include screen(md) {
            display: flex!important;
        }
    }
}