@import '../../../../../../styles/includes.scss';

.treaty__content {
  padding-right: 0px;
  @include screen(md) {
    padding-right: 24px;
  }
  .treaty__nav {
    padding-top: 20px;
    text-align: right;
    font: normal normal normal 16px/22px Open Sans;
    span {
      color: $bostonBlue;
    }
  }
  h1 {
    margin: 0px;
    @include typography(t0);
  }
  .treaty__content-title {
    font-size: 30px;
    line-height: 35px;
  }

  .treaty__goto {
    a {
      cursor: pointer;
      @include typography(p7);
      color: $bostonBlue;
      text-decoration: none;
      .icon {
        color: $bostonBlue;
        padding-left: 20px;
      }
    }
  }
  .treaty__description {
    @include typography(p7);
    color: $black;
    word-spacing: 2px;
    p {
      text-align: justify;
      text-justify: inter-word;
    }

    a {
      color: $bostonBlue;
      word-break: break-word;
    }

    .treaty__description-paragraph-sm {
      margin-left: 2%;
      line-height: 26px;
    }
    .treaty__description-paragraph-md {
      margin-left: 5%;
      line-height: 26px;
    }
    .treaty__description-paragraph-lg {
      margin-left: 10%;
      line-height: 26px;
    }
  }
  .treaty__article-content {
    @include screen(md) {
      margin-top: 95px;
    }
    .article-row {
      padding: 10px 0 20px;
      font: normal normal normal 18px/20px Open Sans;
      color: #65686f;
      @include screen(md) {
        font: normal normal bold 15px/28px Merriweather;
        letter-spacing: 0px;
      }
      a {
        text-decoration: none;
      }
      .article-row__container {
        display: flex;
        margin: 0px;
        padding: 0px;
        &.link {
          font-size: 18px;
          color: $bostonBlue;
          cursor: pointer;
          font-weight: normal;
        }
        &.title {
          color: $black;
          padding: 10px 0;
          font-size: 24px;
          font-weight: bold;
          pointer-events: none;
          cursor: default;
        }
        &.part {
          color: $black;
          padding: 10px 0;
          font-size: 24px;
          font-weight: bold;
          pointer-events: none;
          cursor: default;
        }
        &.section {
          color: $black;
          padding-top: 5px;
          font-size: 22px;
          font-weight: bold;
          pointer-events: none;
          cursor: default;
        }
        &.chapter {
          color: $black;
          font-size: 20px;
          font-weight: bold;
          pointer-events: none;
          cursor: default;
        }
        &.blocked {
          color: $black;
        }
      }
      .article-row__content {
        line-height: 26px;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .chapter .article-row__content {
        font-size: 18px;
      }

      .treaty__article-commentaries {
        margin-top: 10px;
        padding: 0px;        

        @include screen(md) {
          text-transform: uppercase;
          font: normal normal 600 14px/12px Open Sans;
          letter-spacing: 0.14px;
          display: flex;
          align-items: center;
          background-color: #f8f8f8;
          padding: 10px;
          min-height: 57px;
        }

        span {
          font: normal normal 600 12px/16px Merriweather;
          letter-spacing: 0px;
          color: $bostonBlue;
          border-top: 1px solid rgba(112, 112, 112, .2);
          padding: 10px;
          position: relative;

          a {
            padding: 5px 10px;
            color: $bostonBlue;
            text-decoration: none;
            &.item {
              text-decoration: underline;
              border-radius: 2px;
              @include screen(md) {
                text-decoration: none;
                border: 1px solid #0000001f;
              }
              &::before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                background-color: #f8f8f8;
                left: 0;
                top: 0;
                z-index: -1;
                @include screen(md) {
                  display: none;
                }
              }
            }
            &.blocked {
              color: $black;
            }

            .icon {
              display: none;
              @include screen(md) {
                display: inline-block;
              }
            }
          }

          @include screen(md) {
            text-transform: uppercase;
            font: normal normal 600 14px/12px Open Sans;
            letter-spacing: 0.14px;
            padding: 0;
            padding-right: 40px;
            border-top: none;
          }

          &:last-child {
            border-bottom: 1px solid rgba(112, 112, 112, .2);
            @include screen(md) {
              border-bottom: none;
            }
          }
        }
      }
    }
  }

  .treaty-detail__show-modal {
    background-color: #62A3BB;
    font-size: 15px;
    line-height: 28px;
    color: white;
    padding: 10px 30px;
    display: flex;
    min-width: 200px;
    justify-content: space-between;
    margin-left: auto;
    margin-bottom: 30px;
    margin-right: -24px;
    @include screen(md) {
      display: none;
    }
  }

  .treaty-detail__expanded-content {
    display: block;
    height: 100%;
    position: fixed;
    z-index: 20;
    background-color: rgba(0, 0, 0, 0.6);
    top: 50px;
    right: 0;
    left: 0;
    overflow-y: scroll;
    padding-bottom: 50px;

    .treaty-detail__mobile-sidebar {
      margin-left: 25px;
      background-color: #F5f5F5;
      padding: 15px;
      min-height: 100%;

      .item {
        display: flex;

        .value {
          color: #6F6969;         
        }
      }
    }

    .treaty-detail__close-modal {
      background-color: #62A3BB;
      font-size: 15px;
      line-height: 28px;
      color: white;
      padding: 10px 30px;
      display: flex;
      width: 170px;
      justify-content: space-between;
      margin-left: auto;
      margin-right: -15px;
    }

    .share {
      display: none;
    }

    @include screen(md) {
      display: none;
    }
  }
}

html[dir="rtl"] .treaty__content {
  padding-left: 0;
  padding-right: 0;

  @include screen(md) {
    padding-left: 24px;
  }
}