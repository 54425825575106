@import '../../../../styles/includes.scss';

.page-title {
    position: relative;
    display: flex;

    h1 {
        margin-top: 30px;
        margin-bottom: 0px;
    }
}