.wrap {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 20px;
  row-gap: 10px;
}

@media screen and (max-width: 880px) {
  .wrap {
    grid-template-columns: 1fr;
  }
}