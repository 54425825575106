.indicator {
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.indicator i {
  font-size: 19px;
}

.indicatorFilled {
  background-color: #B2C026;
  color: #fff;
}

.indicatorToBeFilled {
  background-color: lighten(#B2C026, 20%);
}

.indicatorBlocked {
  background-color: grey;
}

.indicatorOptional {
  background-color: #f2f2f2;
}