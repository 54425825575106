@import '../../../../../../styles/includes.scss';

.treaty__side-content {
  .item {
    display: none;
    flex-direction: column;
    padding-bottom: 15px;
    @include screen(md) {
      display: flex;
    }
    .label {
      @include typography(p6);
    }
    .value {
      @include typography(p6);
      font-weight: normal;
      line-height: 24px;
      p {
        margin: 0;
      }
    }
    &.documents,
    &.research-guides {
      .value {
        display: flex;
        flex-direction: column;
      }
    }
  }
  
  .share {
    .value {
        font-size: 15px;
        line-height: 24px;
        font-family: Merriweather;
        color: #6F6969;
    }

    @include screen('custom', 'max', '992px') {
        border-top: none;
        display: flex;
        align-items: center;

        .value {
            flex: 1;
            font-weight: bold;
            color: #65686F;
        }

        .button {
            display: none;
        }
    }
  }
}
