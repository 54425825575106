@import '../../../../styles/includes.scss';

.page-container-columns {
  max-width: 1280px;
  margin: 0 auto;
}
.columns__content {
  margin-bottom: 0;
  @include screen(md) {
    margin-bottom: 50px;
    .columns.columns--gap-1 {
      margin-left: 0px;
      margin-right: 0px;
    }
  }

  .column__item {
    color: $white;
    padding: 15px 25px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    background-color: $lightAlto;
    @include screen(md) {
      min-height: 560px;
      padding: 40px 25px;
      height: 100%;
    }

    &.column__item--bg-0 {
      @include theme(map-get($treaties, name)) {
        background-color: map-get($treaties, d-color);
      }
      @include theme(map-get($customary, name)) {
        background-color: map-get($customary, d-color);
      }
      @include theme(map-get($national, name)) {
        background-color: map-get($national, d-color);
      }
    }
    &.column__item--bg-1 {
      @include theme(map-get($treaties, name)) {
        background-color: map-get($treaties, m-color);
      }
      @include theme(map-get($customary, name)) {
        background-color: map-get($customary, m-color);
      }
      @include theme(map-get($national, name)) {
        background-color: map-get($national, m-color);
      }
    }
    &.column__item--bg-2 {
      @include theme(map-get($treaties, name)) {
        background-color: map-get($treaties, l-color);
      }
      @include theme(map-get($customary, name)) {
        background-color: map-get($customary, l-color);
      }
      @include theme(map-get($national, name)) {
        background-color: map-get($national, l-color);
      }
    }

    .column__item-title {
      @include typography(t9);
      color: $white;
      text-decoration: none;
      display: flex;
      margin-right: 0;
      justify-content: space-between;
      align-items: center;
      padding-bottom: 15px;
      .icon {
        margin-left: 15px;
      }
      @include screen(md) {
        cursor: default;
        margin-right: 115px;
        .icon {
          display: none;
        }
      }
    }
    .column__item-description {
      display: none;
      @include typography(p2);
      @include screen(md) {
        display: block;        
      }
      a {
        color: $white;
        cursor: pointer;
      }
      &--m {
        a {
          color: $white;
        }
      }
    }
    .column__item-expandable {
      border-top: 0.5px solid #d3d3d387;
      @include screen(md) {
        display: none;
      }
      .expandable__header-btn {
        position: inherit;
        margin: 0;
        top: 0;
        transform: inherit;
        padding: 15px 0 0;
        text-transform: uppercase;
        font: normal normal normal 14px/12px Open Sans;
        letter-spacing: 0.14px;
      }
      .expandable__content {
        p {
          margin: 15px 0 10px;
          font: normal normal normal 13px/19px Merriweather;
          letter-spacing: -0.15px;
        }
      }
    }
  }
  .column__action {
    display: none;
    @include screen(md) {
      display: flex;
      min-width: 100px;
      justify-content: center;
      a {
        display: flex;
        align-items: center;
        text-decoration: none;
        color: #1f1e1d;
        background-color: $white;
        padding: 20px;
        min-width: 157px;
        justify-content: space-between;
        p {
          margin: 0;
          @include typography(p3);
          padding-right: 15px;
        }
      }
    }
  }
}
