@import '../../../../styles/includes.scss';

.customary-practice__selector {
    margin-top: 30px;
    margin-bottom: 50px;

    .customary-practice__selector-title {
        margin: 0;
        font-size: 14px;
        line-height: 24px;
        font-weight: 600;
        text-transform: uppercase;
        font-family: 'Open Sans', Serif, Arial;
        margin-bottom: 10px;
    }

    .customary-practice__selector-container {
        display: flex;
        position: relative;
        width: 100%;
        flex-direction: column;
        column-gap: 30px;
        row-gap: 15px;

        @include screen(md) {
            width: initial;
            row-gap: 20px;
            flex-direction: row;
        }
    }

    .customary-practice__selector-button {
        display: flex;

        a {
            text-decoration: none;
            color: #ffffff;
            background-color: #62A3BB;
            padding: 13px 30px;
            border: 2px solid #62A3BB;
        }

        i {
            margin-left: 15px;
        }

        &-ghost {
            display: flex;
            margin-bottom: 20px;

            @include screen(md) {
                margin-bottom: 0;
            }

            a {
                text-decoration: none;
                color: #62A3BB;
                background-color: #ffffff;
                border: 2px solid #62A3BB;
                padding: 13px 30px;
            }

            i {
                margin-left: 15px;
            }
        }
    }
}
