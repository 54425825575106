@import '../../../../../styles/includes.scss';

.search-filters {
    padding-bottom: 40px;
    
    .list-filter {
        position: relative;
        margin: 10px 0;

        .select-boxes {
            border-bottom-left-radius: 5px;
            border-bottom-right-radius: 5px;
            background-color: #fff;
            margin-top: -3px;
            position: absolute;
            height: 0;
            width: 100%;
            z-index: 1;
            transition: height 0.5s;
            overflow: hidden;
    
            label {
                display: block;
                margin: 5px 0;
                .label {
                    position: relative;
                    top: -2px;
                }
            }
        }       

        .text-input:hover .select-boxes{
            height: 80px;
            border: 1px solid #ccc;
            border-top: 0;
        }
    }

    #keyword {
        width: 100%;

        @include screen(md) {
            flex: 1;
        }
    }

    .search-filters__section-title {
        display: flex;
        align-items: baseline;

        span {
            font-size: 44px;
            line-height: 48px;
            color: #2A3675;
            font-weight: bold;
        }

        h2 {
            font-size: 25px;
            line-height: 36px;
            margin: 0;
        }
    }
    .search-filters__content {
        display: flex;
        flex-wrap: wrap;
        font-family: 'Open Sans';
        font-size: 14px;
        line-height: 19px;
        column-gap: 15px;

        @include screen(md) {
            margin-right: 15px;
        }

        .select__control {
            border-radius: 5px;
            color: #333333;
            min-height: 48px;
        }

        .input {
            font-size: 14px;
            line-height: 19px;
            color: #333333;
            border-radius: 5px; 
            padding: 0;

            .columns {
                padding: 11px 15px;

                @include screen(md) {
                    padding: 14px 15px;
                }
            }
        }

        p {
            font-size: 14px;
        }

        .search-filters__field-disclaimer {
            font-size: 10px;
            line-height: 19px;
            color: #2A3675;
            padding: 10px 12px;
            background-color: #F8F8F8;
            display: flex;
            font-weight: bold;
        }

        .search-filters__select-error .select__control {
            border-color: red;
        }
    }
}