.wrap {
  padding: 30px 0px 70px;
}

.title {
  margin: 0;
}

.collapse {
  margin-top: 10px;
}


