@import '../../../../styles/includes';

.language-bar {
    @include typography(p4);
    text-align: center;
    padding: 15px 0;
    position: relative;
    .language-bar__content {
      background-color: white;
      width: max-content;
      z-index: 3;
      margin: auto;
      padding: 0 21px;
      max-width: 100%
    }
    .language-selector {
      @include typography(t4);
      cursor: pointer;
      text-decoration: none;
      color: $bostonBlue;
      margin-left: 12px;
      background-color: white;
    }
    ::before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      background-color: $bostonBlue;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      z-index: -1;
    }
  }
  