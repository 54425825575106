.wrap {
  display: grid;
  grid-template-columns: auto 70px 70px;
  column-gap: 20px;
  row-gap: 20px;
  transition: 0.3s;
}

.topSelect,
.selectDocument {
  display: grid;
  grid-template-columns: 2fr 1fr;
  column-gap: 20px;
}

.selectDocumentWrap {
  border: 1px solid #B2C026;
  background: #e7edf1;
  padding: 20px;
  display: grid;
  row-gap: 20px;
}

.selectDocumentContainer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  column-gap: 20px;
  row-gap: 20px;
}

.documentItem {
  display: grid;
  grid-template-columns: 70px auto 70px;
  column-gap: 5px;
  row-gap: 20px;
  align-items: center;
}

.documentList {
  display: grid;
  grid-template-columns: auto 100px;
  column-gap: 20px;
  row-gap: 20px;
}

.triangleDown {
  width: 0;
  height: 0;
  border-left: 17px solid transparent;
  border-right: 17px solid transparent;
  border-top: 30px solid #B2C026;
  margin: 0 auto;
}

.triangleRight {
  width: 0;
  height: 0;
  border-top: 17px solid transparent;
  border-left: 30px solid #B2C026;
  border-bottom: 17px solid transparent;
}

.addCircleWrap {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 880px) {
  .topSelect,
  .selectDocumentContainer,
  .wrap,
  .selectDocument,
  .documentList {
    grid-template-columns: 1fr;
  }

  .addCircleWrap {
    justify-content: center;
  }

  .triangleRight {
    border-left: 17px solid transparent;
    border-right: 17px solid transparent;
    border-top: 30px solid #B2C026;
    border-bottom: none;
  }
}

.loading {
  opacity: 0.3;
}