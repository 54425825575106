@import '../../../../../styles/includes.scss';

.search-database {
    width: 100%;
    .search-database__section-title {
        h2 {
            font-size: 25px;
            line-height: 36px;
            margin: 0;
            margin-bottom: 14px;
        }
    }

    .search-database__content {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        position: relative;

        .expandable {
            width: 100%;
            margin-bottom: 10px;

            @include screen(md) {
                width: 32%;
                margin-bottom: 0;
            }

            .expandable__header {
                background-color: #ffffff;
                padding: 24px 30px 21px 58px;
                cursor: pointer;
                display: flex;
                border-radius: 5px;
                min-height: 80px;
                            
                .expandable__header-btn {
                    left: 0;
                    right: initial;
                    width: 100%;
                    height: 100%;
                    margin: 0;
                }

                h4 {
                    font-size: 15px;
                    line-height: 17px;
                    font-family: 'Open Sans';
                    color: #333333;
                    margin: 0;
                    display: flex;
                    align-items: center;
                    flex-wrap: wrap;
                }
            }

            .expandable__content {
                display: flex;
                overflow: visible;

                img {
                    height: 17px;
                    margin-left: 10px;
                    margin-top: 24px;
                }
            }

            .search-database__np-section {
                flex-direction: column;

                .search-database__np-row {
                    display: flex;
                }
            }

            .search-database__dropdown-items {
                padding: 10px 0 10px 13px;
                font-size: 14px;
                line-height: 24px;
                font-family: 'Open Sans';
                display: flex;
                flex: 1;

                &.cihl {
                    display: flex;
                    margin-top: 10px;
                    margin-bottom: 5px;

                    @include screen(md) {
                        margin-bottom: 0;
                    }
                }

                .select__control {
                    padding: 5px 0;
                }

                .select__menu {
                    z-index: 5;
                }
            }

            .search-database__cihl-checkbox {
                position: relative;
                cursor: pointer;
                display: flex;
                margin-right: 20px;

                span {
                    width: 24px;
                    height: 24px;
                    border: 1px solid #DBDBDB;
                    border-radius: 4px;
                    margin-right: 8px;
                }

                &-selected {
                    font-weight: 600;

                    span {
                        background-color: #62A3BB;
                        width: 24px;
                        height: 24px;
                        border: 1px solid #62A3BB;
                        border-radius: 4px;
                        display: block;
                        position: relative;

                        &::before {
                            content: '';
                            position: absolute;
                            left: 8px;
                            top: 2px;
                            width: 4px;
                            border-bottom: 3px solid #ffffff;
                            height: 11px;
                            border-right: 3px solid #ffffff;
                            transform: rotate(45deg);
                            -o-transform: rotate(45deg);
                            -ms-transform: rotate(45deg);
                            -webkit-transform: rotate(45deg);
                        }
                    }
                }
            }

            &--expanded {
                .expandable__header {
                    background-color: #62a3bb;

                    h4 {
                        color: #ffffff;
                    }
                }

                .search-database__dropdown-items {
                    display: block;
                }
            }
        }
    }

    .search-database-button {
        display: flex;
        justify-content: center;
        padding: 35px 0;

        .custom-button {
            background-color: #62A3BB;
        }
    }

    /* Customize the label (the container) */
    .custom-checkbox-label {
        display: block;
        position: relative;
        cursor: pointer;
        height: 100%;
        width: 100%;
        color: #fff;
        text-transform: uppercase;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .custom-checkbox-label input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .checkmark {
        position: absolute;
        top: 50%;
        left: 16px;
        transform: translateY(-50%);
        margin: 0 auto;
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background-color: #fff;
    }

    /* Create the checkmark/indicator (hidden when not checked) */
    .checkmark:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .custom-checkbox-label input:checked~.checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .custom-checkbox-label .checkmark:after {
        width: 5px;
        height: 12px;
        border: solid #62a3bb;
        border-width: 0 3px 3px 0;
        left: 50%;
        top: -1px;
        transform: rotate(45deg) translate(50%, 50%);
    }
}