@import '../../../../styles/includes';

.grid-home {
  @include screen(sm) {
    background-repeat: no-repeat;
    background-position: bottom;
    margin: 10px 0 10px;
    .bars-horizontal {
      height: 20px;
      width: 100%;
      background-color: $white;
    }
    .grid-home__content {
      display: flex;
 
      .bars-vertical {
        height: 450px;
        width: 20px;
        background-color: $white;
      }
      &.grid-home__last {
        justify-content: space-between;
        .group-item {
          height: 100%;
        }
      }
    }
  }
  .grid-home__item {
    width: 100%;
  }
  @include screen(sm) {
    .grid-home__item {
      height: 450px;
      background-color: transparent;
      width: 300px;

      &.grid-home__item-last {
        width: 320px;
      }

      & > .group-item {
        padding: 25px 20px;
      }
    }
  }

  .grid-home__item--last {
    @include screen(sm) {
      margin-right: 0;
      width: 320px;
    }
  }
}
