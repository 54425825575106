.wrap {
  height: 40px;
  display: grid;
  grid-template-columns: 30px auto;
}

.indicator {
  background-color: red;
}

.title {
  display: flex;
  align-items: center;
  margin: 0 0 0 10px;
}