.icrc-modal-container {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-color: rgba(0,0,0,0.6);
    text-align: center;
    z-index: 10;
}

.icrc-modal {
    background-color: #fff;
    position: relative;
    display: inline-block;
    top: 50%;
    transform: translateY(-50%);
    z-index: 100;
    padding: 25px;
    max-width: 96%;
    max-height: 100%;
    overflow-y: auto;
    border: 1px solid #ccc;
    color: #000;  

    .icrc-modal__title {
        text-align: center;

        h2 {
            margin-top: 0;
        }
    }
    .icrc-modal__close {
        cursor: pointer;
        position: absolute;
        right: 10px;
        top: 10px;
    }
}

html[dir="rtl"] {
    .icrc-modal {
        text-align: right;
        direction: rtl;

        @media only screen and (max-width: 1025px) and (min-width: 800px) {
            max-width: 90%;
        }

        @media (max-width: 290px) {
            div {
                font-size: 12px;

                b {
                    display: inline-block;
                }
            }
        }
    }
}