.additional-filters__label {
    background-color: #46846C;
    color: white;
    border-radius: 20px;
    padding: 5px 10px;
    margin: 5px 0;
    font-size: 12px;
    cursor: pointer;
    display: grid;
    grid-template-columns: 1fr 40px;
    span {
        text-align: right;
    }
}
.additional-filters__show-all {
    padding: 5px 10px;
    margin: 5px 0;
    font-size: 12px;
    cursor: pointer;
    span {
        float: right;
    }
}
