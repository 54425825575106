@import '@icrc/react-ui/scss/colors.scss';
@import '../../styles/includes.scss';

.header {
  z-index: 20;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: $white;
  height: 50px;
  @include screen(md) {
    height: 91px;
    background-size: cover;
  }
  .header__content {
    height: 50px;
  }
}
