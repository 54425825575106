.wrap {
  display: flex;
  align-items: center;
  column-gap: 10px;
  padding: 4px 0;
  font-size: 16px;
  font-weight: 400;
}

.title {
  padding: 0;
  margin: 0;
  font-size: 16px;
  font-weight: 400;
}

.content {
  word-break: break-word;
}


