.input {
  width: 100%;
  border: 1px solid lightgrey;
  padding: 4px 12px;
  outline: none !important;
  border-radius: 8px;
  transition: 0.5s;
  height: 120px;
  resize: none;
}


.input:focus-visible,
.input:hover {
  border: 1px solid grey;
}

.inputError {
  border: 1px solid #d92e2e !important;
}