.button {
  max-width: 250px;
  margin: 0 auto;
  width: 100%;
  height: 40px;
  background: #636b15;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 19px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: Merriweather, serif;
  color: #fff;
  border: none;
  cursor: pointer;
  transition: 0.5s;
}

.button:hover {
  background: lighten(#B2C026, 10%);
}

.buttonDisabled {
  opacity: 0;
}