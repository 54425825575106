@import '../../../../styles/includes.scss';

.search-landing {
  .search-landing__title {
    padding: 50px 0;
    text-align: center;

    h1 {
      margin: 0;
    }
  }

  .search-landing__content {
    padding: 4px;
    margin-bottom: 70px;

    @include screen(md) {
      padding: 40px;
      border: 1px solid #DBDBDB;
      margin-bottom: 70px;
    }
  }

  .search-landing__footer {
    .custom-button {
      background-color: #76A5B9;
      text-decoration: none;
      padding: 5px 20px;
    
      span {
        position: relative;
        margin-top: 1px;
      }
    
      &:hover {
        background-color: #29B0D8;
      }
    
      i {
        margin-left: 8px;
      }
    }
  }
}