@import '../../../../../../styles/includes.scss';

.search-results__sidebar-content {   

    h3 {
        font-size: 22px;
        line-height: 46px;
        font-weight: bold;
        font-family: 'Open Sans';
        margin-top: 30px;
        margin-bottom: 36px;
    }

    .search-results__applied-filters {
        display: flex;
        flex-wrap: wrap;

        .search-results__active-filter {
            display: flex;
            background-color: #62A3BB;
            padding: 6px 12px;
            border-radius: 16px;
            align-items: center;

            p {
                font-size: 14px;
                line-height: 20px;
                color: #ffffff;
                font-family: 'Open Sans';
                margin: 0;
            }

            span {
                height: 14px;
                width: 14px;
                background-color: #ffffff;
                border-radius: 50%;
                text-align: center;
                display: flex;
                margin-left: 10px;
                justify-content: center;

                i {
                    color: #62A3BB;
                    font-size: 12px;
                    line-height: 12px;
                    height: 12px;
                    width: 12px;
                    margin-top: 1px;
                }
            }
        }

        .search-database__section-title h2 {
            font-family: 'Open Sans';
            font-size: 14px;
            line-height: 17px;
        }

        .search-filters__section-title {
            display: none;
        }
    }
    
    .search-results__language {
        margin: 30px 0;

        span {
            font-size: 12px;
            line-height: 28px;
            color: #1F1E1D;
            font-weight: bold;
            display: block;
        }
        select {
            border: 1px solid #DBDBDB;
            width: 100%;
            margin-top: 5px;
            padding: 14px 15px;
            border-radius: 5px;
            font-size: 14px;
            line-height: 19px;
            color: rgba(51, 51, 51, .5);
        }
    }

    .search-database__content {
        .expandable {
            width: 100%;
            margin-bottom: 10px;
        }  
        
        .expandable__header {
            padding: 8px 24px 8px 58px!important;
            min-height: 48px!important;
        }

        .checkmark {
            height: 20px;
            width: 20px;

            &:after {
                width: 3px;
                height: 8px;
                left: 45%;
                top: -3px;
            }
        }

        .search-database__dropdown-items {
            @include screen(md) {
                max-width: calc(100% - 27px);
            }
        }
    }

    .search-filters {
        width: 100%;

        .list-filter {
            width: 100%;
        }

        .search-filters__content {
            margin-right: 0;

            .select__menu {
                z-index: 9;
            }
        }
    }
}