@import './styles/includes.scss';

.app {
    text-align: center;

    @include screen(md) {
        background-color: #f00;
    }
}

.toast-container {
    background: #8bc34a;
    color: #fff;
}

.lowercase {
    text-transform: lowercase;
}

.uppercase {
    text-transform: uppercase;
}

.capitalize {
    text-transform: capitalize;
}

.cPointer {
    cursor: pointer;
}

.noBreak {
  position: relative !important;
  page-break-after: always;
}
