.wrap {
  display: grid;
  grid-template-columns: 2fr 1fr;
}

.container {
  display: grid;
  row-gap: 20px;
}

@media screen and (max-width: 880px) {
  .wrap {
    grid-template-columns: 1fr;
  }
}