
.descriptionIcon {
    font-size: 12px;
    cursor: pointer;
    z-index: 15 !important;
    display: block;
}

.description {
    display: none;
    position: absolute;
    background: #fff;
    border: 1px solid silver;
    padding: 10px;
    border-radius: 10px;
    font-family: Merriweather, sans-serif, arial;
    font-size: 11px;
    max-width: 120px;
    z-index: 15;
    word-break: break-word;
    cursor: pointer;
}

.descriptionWrap:hover .description {
    display: block;
}

.descriptionIcon {
    font-size: 12px;
    cursor: pointer;
    z-index: 15 !important;
    display: block;
}

.description {
    display: none;
    position: absolute;
    background: #fff;
    border: 1px solid silver;
    padding: 10px;
    border-radius: 10px;
    font-family: Merriweather, sans-serif, arial;
    font-size: 11px;
    min-width: 120px;
    max-width: 150px;
    z-index: 15;
    word-break: break-word;
    cursor: pointer;
}

.descriptionWrap:hover .description {
    display: block;
}
