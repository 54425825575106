@import '../../../../../../styles/includes.scss';

.national-practice-list_item-content {
    width: 100%;
    
    .national-practice-list_item-content_flex {
        display: flex; 
        flex-wrap: wrap;
        width: 100%;

        > div {
            flex: 0
        }

        .national-practice-list__sub-title__country {
            white-space: pre-wrap; 
            @include screen(md) {
                white-space: nowrap; 
            }
        }
    }
}