@import '../../styles/includes.scss';

.state-parties {
  padding: 30px 15px;

  .state-parties__back {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font: normal normal normal 18px/28px Open Sans;
    letter-spacing: -0.18px;
    a {
      color: $bostonBlue;
      @include typography(t4);
      cursor: pointer;
      text-decoration: none;
    }
  }
}
