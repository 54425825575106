@import '../../styles/includes.scss';

.commentary-view {
  padding-top: 30px;

  h1 {
    font-size: 24px;
  }
  .commentary-view__content {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    @include screen(md) {
      flex-direction: row;
      justify-content: space-between;
    }

    .commentary-view__content-main {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 100%;
      line-height: 35px;

      @include screen(md) {
        width: 60%;
        padding-right: 24px;
      }

      .commentary-view__back {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        font: normal normal normal 18px/28px Open Sans;
        letter-spacing: -0.18px;
        a {
          color: $bostonBlue;
          @include typography(t4);
          cursor: pointer;
          text-decoration: none;
        }
      }
      .commentary-view__article-title {
        font-size: 18px;
        font-weight: normal;
        a {
          color: $bostonBlue;
          text-decoration: none;
          width: 100%;
          &.blocked {
            color: $black;
          }
        }
      }
      .commentary-view__description {

        @include typography(p2);
        padding: 15px 0;
        text-align: justify;
        text-justify: inter-word;

        p {
            margin: 0;
        }

        .commentary-view__broken-link {
          display: none;
        }
      }
    }
    .commentary-view__content-side {
      width: 100%;
      @include screen(md) {
        width: 32%;
      }
      .commentary-view__navigation {
        display: none;
        @include screen(md) {
          display: block;
          height: 550px;
          overflow-y: scroll;
          margin-bottom: 30px;
          border: 1px solid #fafafa;
          background-color: #fafafa;
        }
      }
      .share {
        .button {
          display: none;

          @include screen(md) {
            display: inline-block;
          }
        }
      }
    }
    .commentary-float {
      display: block;
      position: fixed;
      bottom: 0;
      right: 0;
      left: 0;
      height: 112px;
      .commentary-float__actual {
        @include typography(t7);
        font-weight: 600;
        line-height: 25px;
        text-transform: uppercase;
        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        color: $white;
        @include theme(map-get($treaties, name)) {
          background-color: map-get($treaties, m-color);
        }
        @include theme(map-get($customary, name)) {
          background-color: map-get($customary, m-color);
        }
        @include theme(map-get($national, name)) {
          background-color: map-get($national, m-color);
        }
        div {
          padding-right: 5px;
        }
      }
      .commentary-float__navigation {
        @include typography(p4);
        background-color: white;
        color: #6f6969;
        height: 56px;
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        align-items: center;
      }
      @include screen(md) {
        display: none;
      }
    }
    .commentary-float__expanded-content {
      display: block;
      height: 100%;
      position: fixed;
      z-index: 20;
      background-color: white;
      top: 50px;
      right: 0;
      left: 0;
      overflow-y: scroll;
      padding-bottom: 50px;
      .commentary-float__actual {
        @include typography(t7);
        font-weight: 600;
        line-height: 25px;
        text-transform: uppercase;
        height: 56px;
        display: flex;
        align-items: center;
        padding: 0 10px;
        color: $white;
        @include theme(map-get($treaties, name)) {
          background-color: map-get($treaties, m-color);
        }
        @include theme(map-get($customary, name)) {
          background-color: map-get($customary, m-color);
        }
        @include theme(map-get($national, name)) {
          background-color: map-get($national, m-color);
        }
        div {
          padding-right: 5px;
        }
      }
      @include screen(md) {
        display: none;
      }
    }
  }
  .commentary-view__navigation-item {
    display: flex;
    flex-direction: column;
    height: auto;
    border-bottom: 1px solid $lightAlto;
    padding: 10px 20px;
    a {
      color: $bostonBlue;
      text-decoration: none;
      width: 100%; 
      &.blocked{ 
        color:#65686f;
      }
      .line {
        .description {
          white-space: nowrap;
          width: 300px;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
      div {
        padding-right: 5px;
      }
    }

    .commentary-view__navigation-item-title {
      line-height: 26px;
      white-space: normal;
      overflow: hidden;
      text-overflow: ellipsis;
      padding: 5px 0;

      @include screen(md) {
        white-space: nowrap;
      }
    }

    .commentary-view__navigation-item-commentary {
      font-size: 14px;
      padding: 5px;

      &.active {
        &.left {
          @include theme(map-get($treaties, name)) {
            background-color: map-get($treaties, d-color);
          }
        }

        &.middle {
          @include theme(map-get($treaties, name)) {
            background-color: map-get($treaties, m-color);
          }
        }

        &.right {
          @include theme(map-get($treaties, name)) {
            background-color: map-get($treaties, l-color);
          }
        }

        &.blocked {
          pointer-events: none;
          cursor: default;
        }

        border-top-left-radius: 10px;
        border-bottom-left-radius: 10px;
        a {
          color: $white;
        }
      }

      .item {
        font-weight: bold;
      }
    }
  }
}
