@import '../../../../styles/includes';

.grid {
  margin: 20px 0 50px;
  .grid__item {
    height: 150px;

    margin: 1px 0;

    @include screen(sm) {
      margin: 10px 20px 0 0;
      height: 280px;
    }

    & > .group-item {
      padding: 25px 20px;
      height: 100%;
      display: flex;
      align-items: center;
      @include screen(sm) {
        align-items: flex-start;
        justify-content: space-between;
      }
    }
  }

  .grid__item--last {
    @include screen(sm) {
      margin-right: 0;
    }
  }
}