@import '../../../../styles/includes.scss';

.text-box {
    p {
        font-size: 15px;
        line-height: 24px;
        color: #1F1E1D;
    }   

    a {
        color: #62A3BB;
        text-decoration: none;
    }
}