@import url('https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;600;700&display=swap');
@import '@icrc/react-ui/index.css';
@import './includes.scss';

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Merriweather', sans-serif;
  font-weight: 400;
  background-color: $white;
  color: $black;
  margin: 0;
  width: 100%;
}

a {
  &.blocked {
    pointer-events: none;
    cursor: default;
  }
}

.page-container {
  margin: 0 auto;
  max-width: 1240px;
  padding: 0 20px;
}

.component-container {
  padding-left: 16px;
  padding-right: 16px;
}

.GC_BIBLIOGRAPHY {
  font-size: 16px;
}

.GC_BULLET {
  margin-left: 30px;
  margin-bottom: 5px;
  text-align: justify;
}

.GC_CHAPTER_HEADING {
  font-weight: bold;
  font-size: 20px;
  line-height: 26px;
  margin: 0 0 8px;
  padding: 0 0 6px;
  text-transform: uppercase;
}

.GC_CONTENTS {
  font-weight: bold;
  margin-top: 10px;
}

.GC_CRYSTAL_BULLET {
  font-weight: bold;
  margin-top: 10px;
}

.GC_HEADING_1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
}

.GC_HEADING_2 {
  margin-top: 10px;
  margin-bottom: 5px;
  font-weight: bold;
}

.GC_HEADING_3 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}

.GC_HEADING_4 {
  margin-top: 5px;
  margin-bottom: 5px;
  font-weight: bold;
}

.GC_NORMAL {
  margin-bottom: 5px;
  text-align: justify;
}

.GC_NORMAL_UNNUMBERED {
  margin-bottom: 5px;
  text-align: justify;
}

.GC_NUMBERED_BULLET {
  margin-left: 30px;
  margin-bottom: 5px;
  text-align: justify;
}

.GC_QUOTE {
  margin-left: 30px;
  margin-bottom: 5px;
  font-size: 16px;
  text-align: justify;
}

.GC_QUOTE_INDENT {
  margin-left: 60px;
  margin-bottom: 5px;
  font-size: 16px;
  text-align: justify;
}

.GC_SELECT_BIBLIOGRAPHY {
  font-style: italic;
}

.GC_TEXT_OF_THE_PROVISION {
  margin-bottom: 5px;
  text-align: justify;
}

.GC_TEXT_OF_THE_PROVISION_INDENT {
  margin-bottom: 5px;
  margin-left: 40px;
  text-align: justify;
}

.GC_TOC_AUTHORS {
  font-style: italic;
  margin-bottom: 10px;
}

.GC_TOC_Heading_1 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 18px;
}

.GC_TOC_Heading_2 {
  margin-top: 20px;
  margin-bottom: 10px;
  font-weight: bold;
}

.GC_TOC_1 {
  padding-left: 20px;
}

.GC_TOC_2 {
  padding-left: 40px;
}

.GC_TOC_3 {
  padding-left: 60px;
}

// Anchors fixes of scroll margin top.
a[href^="#"],
a[id^="index_Toc"],
a[id^="_Toc"],
a[id^="c__Toc"]
a[id^="Fn_"]
div[id^="_Toc"],
div[id^="c__Toc"],
div[id^="Fn_"],
.GC_FOOTNOTE {
  scroll-margin-top: 113px;
  @include screen(md) {
    scroll-margin-top: 204px;
  }
}
