.expandable {
  .expandable__header {
    position: relative;
  }

  .expandable__header-btn {
    position: absolute;
    top: 50%;
    margin: 0 5px;
    transform: translateY(-50%);
    right: 0;
  }

  .expandable__content {
    height: auto;
    max-height: 0;
    overflow: hidden;
    transition: max-height 0.3s ease-in;
  }

  &.expandable--expanded {
    > .expandable__content {
      max-height: 10000px;
      transition: max-height 0.6s ease-in;
    }
  }
}

div[dir="rtl"] {
  .expandable__header-btn {
    left: 0;
    right: initial;
  }
}
