@import '../../../../styles/includes.scss';

.national-practice-list__content {
  @include screen(md) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    flex-direction: row;
    flex-wrap: wrap;
    background-color: transparent;
  }

  .list-loadmore-button {
    width: 100%;
    text-align: center;
    margin: 10px;
  }

  .national-practice__sort {
    display: flex;
    justify-content: flex-start;
    padding: 15px 0 0 20px;
    @include screen(md) {
      padding: 5px 0 15px 0;
    }

    span {
      font-size: 15px;
      display: flex;
      align-self: center;
      font-weight: bold;
      @include screen(md) {
        font-size: 12px;
      }
    }
    .input {
      width: 140px;
      font-size: 14px;
      border-radius: 0;
      border: 0;
      @include screen(md) {
        font-size: 12px;
      }

      .icon {
        font-size: 20px;
        width: 20px;
        height: 20px;
        line-height: 20px;
        padding-top: 2px;
        @include screen(md) {
          font-size: 16px;
        }
      }
      .dropdown__wrapper {
        top: 15%;

        @include screen(md) {
          top: 0;
        }
      }
      .dropdown__content {
        color: $greyLight;
        .dropdown-option.dropdown-option--selected {
          color: $black;
          font-weight: bold;
          background-color: transparent;
        }
      }
    }
  }

  .dropdown {
    z-index: 11;
    .dropdown__container {
      width: 150px;
    }
  }
}
