.checkbox-filters {
    margin-top: 10px;
    margin-bottom: 10px;
    label {
        margin-right: 10px;
        cursor: pointer;
        span {
            font-size: 14px;
            position: relative;
            top: -2px;
            margin-left: 2px;
            color: #6c6ccb;
        }
    }
}