@import '../../../../styles/includes.scss';

.image-grid {
  .image-grid__title {
    display: flex;
    justify-content: center;
    font: normal normal normal 25px/42px Merriweather;
    letter-spacing: -0.5px;
    padding-top: 30px;
    padding-bottom: 17px;
    @include screen(md) {
      justify-content: flex-start;
      padding-top: 0px;
      padding-bottom: 30px;
    }
  }
  .image-grid__content {
    display: flex;
    flex-direction: column;
    padding: 0 15px;
    @include screen(md) {
      padding: 0 0 30px 0;
      flex-direction: row;
    }

    .image-grid__content-item {
      padding-bottom: 30px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-direction: column;
      text-decoration: none;
      @include screen(md) {
        padding-bottom: 0;
        padding-right: 80px;
        align-items: flex-start;
      }
      .image-grid__logo {
        display: flex;
        align-items: center;
        img {
          max-width: 328px;
          max-height: 85px;

          padding-bottom: 10px;
          @include screen(md) {
            padding-bottom: 15px;
            max-height: 100px;
          }
        }
      }
      .image-grid__label {
        font: normal normal bold 12px/14px Open Sans;
        text-transform: uppercase;
        color: #b2b2b2;
        letter-spacing: 0.14px;
        display: flex;
        align-items: center;
        @include screen(md) {
          align-items: flex-start;
        }
      }
    }
  }
}
.theme--customary-ihl {
  .image-grid__content-item {
    @include screen(md) {
      width: 33.33333%;
      flex: 0 0 33.33333%;
      flex-grow: 0;
      flex-shrink: 0;
      flex-basis: 33.33333%;
      padding-left: 10px;
      padding-right: 10px;
      box-sizing: border-box;
    }
  }
  .page-container {
      padding: 0 20px;
  }
}