

.loader {
  height: 100%;
  .overlay {
    background-color: transparent;
    .icon {
      color: $lightAlto;
    }
  }
}