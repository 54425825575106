@mixin typography($type) {
  // titles
  @if $type == 't0' {
    font: normal normal bold 26px/36px Merriweather;
    letter-spacing: -0.46px;
    @include screen(sm) {
      font: normal normal bold 46px/56px Merriweather;
    }
  }
  @if $type == 't1' {
    font: normal normal bold 35px/46px Merriweather;
    letter-spacing: 0;
    @include screen(sm) {
      font: normal normal normal 46px/56px Merriweather;
      letter-spacing: 0;
    }
  }
  @if $type == 't2' {
    font: normal normal 600 30px/32px Open Sans;
  }
  @if $type == 't3' {
    font: normal normal bold 24px/30px Merriweather;
    letter-spacing: 0px;
  }
  @if $type == 't4' {
    font: normal normal bold 15px/28px Merriweather;
    letter-spacing: 0px;
  }
  @if $type == 't5' {
    font: normal normal bold 14px/32px Merriweather;
    @include screen(sm) {
      font: normal normal bold 32px/32px Merriweather;
    }
  }
  @if $type == 't6' {
    font: normal normal bold 16px/16px Merriweather;
  }
  @if $type == 't7' {
    font: normal normal bold 14px/12px Open Sans;
    letter-spacing: -0.14px;
  }
  @if $type == 't8' {
    font: normal normal 600 12px/16px Open Sans;
    letter-spacing: 0.14px;
    @include screen(sm) {
      font: normal normal 700 12px/16px Open Sans;
      letter-spacing: 0.14px;
    }
  }
  @if $type == 't9' {
    font: normal normal bold 19px/23px Merriweather;
    letter-spacing: 0;
    @include screen(sm) {
      font: normal normal bold 24px/30px Merriweather;
      letter-spacing: 0;
    }
  }

  // texts
  @if $type == 'p1' {
    letter-spacing: 0.14px;
    font: normal normal normal 14px/20px Merriweather;
  }
  @if $type == 'p2' {
    font: normal normal normal 15px/28px Merriweather;
    letter-spacing: 0px;
  }
  @if $type == 'p3' {
    font: normal normal bold 13px/16px Merriweather;
    letter-spacing: -0.13px;
  }
  @if $type == 'p4' {
    font: normal normal normal 12px/24px Open Sans;
    letter-spacing: 0px;
  }
  @if $type == 'p5' {
    font: normal normal normal 10px/12px Open Sans;
    @include screen(md) {
      font: normal normal normal 12px/16px Open Sans;
    }
  }
  @if $type == 'p6' {
    font: normal normal bold 14px/28px Open Sans;
    letter-spacing: -0.28px;
  }
  @if $type == 'p7' {
    font: normal normal normal 16px/26px Merriweather;
    letter-spacing: -0.18px;
    @include screen(md) {
      font: normal normal normal 15px/28px Merriweather;
    }
  }
}

//use:   @include typography(t1);
