@import '../../../../../../styles/includes.scss';

.customary-practice__section {
    margin-bottom: 30px;
    display: inline-block;
    width: 100%;

    .customary-practice__section-title {
        padding: 8px 3px;
        border-bottom: 1px solid silver;

        h3 {
            font-family: Merriweather, Sans-Serif, Arial, serif;
            color: #252525;
            font-size: 25px;
            margin: 0;
        }
    }
}