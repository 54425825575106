.button {
    padding: 12px 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid transparent;
    font-family: Merriweather, sans-serif, arial;
    background-color: #f0f5fd;
    cursor: pointer;
}


.remove {
    background-color: #a50044;
    color: #fff;
}

.action {
    background-color: #218BC3;
    color: #fff;
}
