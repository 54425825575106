.button {
  height: 48px;
  padding: 4px 25px;
  background-color: #62A3BB;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: Merriweather, sans-serif, arial;
  color: #fff;
  font-weight: 500;
  font-size: 18px;
  column-gap: 10px;
  cursor: pointer;
  transition: 0.5s;
  border: 2px solid transparent;
}

.dec {
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  position: relative;
}

.button:hover {
  background-color: #fff;
  border: 2px solid #62A3BB;
  color: #62A3BB;
}

.dec,
.dec:before,
.dec:after {
  height: 2px;
  width: 20px;
  background: #fff;
  border-top-right-radius: 2px;
  border-bottom-right-radius: 2px;
  transition: 0.5s;
}

.dec:before,
.dec:after {
  content: '';
  position: absolute;
  width: 10px;
  -webkit-transform-origin: right bottom;
  -moz-transform-origin: right bottom;
  -ms-transform-origin: right bottom;
  -o-transform-origin: right bottom;
  transform-origin: right bottom;
}

.dec:before {
  transform:  rotate(30deg);
}

.dec:after {
  transform:  rotate(-30deg);
}

.button:hover .dec:before,
.button:hover .dec:after,
.button:hover .dec {
  background: #9dc1d3;
}

html[dir="rtl"] {
  .dec:before,
  .dec:after {
    right: 0;
  }
}

