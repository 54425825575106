@import '../../../../styles/includes.scss';

.treaty-detail {
  display: flex;
  flex-direction: column;
  padding: 30px 4px 0;
  @include screen(md) {
    flex-direction: row;
  }

  justify-content: center;

  .treaty__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    @include screen(md) {
      width: 80%;
    }
  }
  .treaty__side-content {
    width: 100%;
    @include screen(md) {
      width: 21%;
    }
  }
}
