@import '../../../../../../styles/includes.scss';

.search-results__np-item {

    .search-results__discalimer {
        margin-bottom: 30px;
    }

    .search-results__np-item-country {
        margin: 0;
    }

    .search-results__np-item-topic {
        font-size: 12px;
        line-height: 28px;
        margin: 10px 0;

        span {
            font-size: 16px;
            line-height: 28px;
            font-family: 'Open Sans';
            font-weight: bold;
            color: #2A3675;
            margin-left: 30px;
        }
    }

    .national-practice-list__sub-title {
        @include typography(p4);
        display: flex;
        align-items: center;
        color: $greyLight;

        .national-practice-list__sub-title__country {
            font-weight: bold;
            color: #1F1E1D;
            text-transform: uppercase;
        }

        .national-practice-list__sub-title-shave {
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .separator {
            margin: 0 5px;
        }

        .bullet {
            display: inline;
            width: 3px;
            height: 3px;
            border-radius: 20px;
            background-color: $greyLight;
        }
    }

    .national-practice-list_title {
        @include typography(t4);
        color: #62A3BB;
        padding-right: 10px;
        text-decoration: none;
    }

    .search-results__np-item-document {
        padding: 15px 0;
        border-top: 1px solid rgba(112, 112, 112, 0.1);
        max-width: 700px;
    }

    .search-results__more-btn {
        background-color: #62A3BB;
        font-size: 13px;
        line-height: 12px;
        display: inline-block;
        margin-top: 15px;
        margin-bottom: 30px;
        a, div {
            display: flex;
            text-decoration: none;
            cursor: pointer;
            
            i {
                color: #ffffff;
                padding-top: 13px;
                margin-right: 20px;
            }
        }
        p {
            padding: 20px;
            color: #ffffff;
            align-items: center;
            margin: 0;
    
            span {
                margin-left: 4px;
            }
        }
    }

}