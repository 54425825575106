
.wrap {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 12px 8px;
    border-bottom: 0.5px solid silver;
    cursor: pointer;
}

.name {
    font-family: Merriweather, serif;
    font-size: 12px;
}

.nameChild {
    padding-left: 10px;
}

