@import '../../../../styles/includes.scss';

.page-container {
  margin: auto;
  max-width: none;
  width: 100%;
  @include screen(md) {
    margin: 0 auto;
    max-width: 1280px;
  }
}
.list-filters-label {
  display: flex;
  padding: 20px 4px;
  font-family: 'Open Sans';
  font-size: 15px;
  line-height: 17px;
  color: #000;
  font-weight: bold;
  justify-content: space-between;
  @include screen(md) {
    display: none;
  }

  .icon {
    font-size: 20px;
    line-height: 17px;
    width: 20px;
    height: 20px;
    opacity: .6;
  }
}
.select__input-container {
  flex: 0 !important;
}

.list-filters-content {
  display: none;
  @include screen(md) {
    display: flex;
  }
  &.active {
    display: flex;
    position: relative;
    padding: 0 4px;
    .list-filter-list {
      flex-direction: column;
      order: 0;
    }
    .additional-filters-container {
      order: 1;
      margin-bottom: 0;
    }
    .list-filter.btn {
      order: 2;
    }


    &::before {
      content: "";
      position: absolute;
      height: 1px;
      left: -20px;
      top: 0x;
      right: -20px;
      background-color: #ccc;
    }
  }
}

.theme--ihl-treaties {
  .custom-button:hover {
    background-color: #bd2929;
  }
}
.theme--national-practice {
  .custom-button:hover {
    background-color: #31664b;
  }
}
.theme--upload {
  .custom-button:hover {
    background-color: #959f30;
  }
}

.dropdown {
  z-index: 11;
}
.list-filter-list {
  display: flex;
  flex: 1;
  flex-wrap: wrap;
  justify-content: space-between;
}
.list-filter {
  width: 100%;
  margin: 10px 0;
  @include screen(md) {
    margin: 10px 1%;
    width: 23%;
  }

  .dropdown__wrapper {
    top: 15%;

    @include screen(md) {
      top: 0;
    }
  }
  p {
    font-size: 12px;
    font-weight: 600;
  }
  &.btn {
    display: flex;
    min-width: 310px;
    justify-content: space-between;
    align-items: top;
    margin-top: 30px;
    flex-wrap: wrap;
    @include screen(md) {
      margin-top: 49px;
    }   

    .clear {
      font: normal normal 600 10px/12px Open Sans;
      cursor: pointer;
      text-transform: uppercase;
      @include theme(map-get($treaties, name)) {
        color: map-get($treaties, m-color);
      }
      @include theme(map-get($customary, name)) {
        color: map-get($customary, m-color);
      }
      @include theme(map-get($national, name)) {
        color: map-get($national, m-color);
      }
    }
  }

  .list-filter-button {
    margin-right: 0px;
    @include screen(md) {
      margin-right: 5px;
    }

    .custom-button {
      min-height: 48px;
      display: flex;
      align-items: center;
      @include screen(md) {
        min-height: initial;
      }
    }
  }
  .react-select-container {
     .select__indicator svg {
         color: #62a3bb;
         margin: 0 5px;
         font-size: 20px;
     }
  }

  .input {
    border-radius: 5px;
    background-color: $white;
    font-size: 14px;
    line-height: 19px;
    padding: 6px 0px;
    @include screen(md) {
      border-radius: 0;
      padding: 0;
      font-size: 12px;
      line-height: 34px;
    }
  }

  .select__control {
    border-radius: 5px;
    min-height: 48px;

    @include screen(md) {
      border-radius: 0;
      min-height: 38px;
    }
  }

  .dropdown__content {
    color: $greyLight;
    .dropdown-option {
      font-size: 12px;
      &.dropdown-option--selected {
        color: $black;
        font-weight: bold;
        background-color: transparent;
      }
    }
    .dropdown-option-child {
      padding-left: 20px;
      padding-right: 0;
    }
  }
}
.additional-filters-container {
  padding: 5px 10px 20px;

  .title {
    cursor: pointer;
    font-size: 15px;
    line-height: 28px;
    margin-top: 18px;
    margin-right: 10px;
  }
  .list-filter-list {
    justify-content: start;

    .list-filter {
      max-width: 227px;
    }
  }
}

.list-filters {
  .additional-filters-container {
    background-color: #31664b;
    color: #fff;
    width: calc(100% + 48px);
    margin-left: -24px;
    margin-right: -24px;
    margin-bottom: -11px;
    margin-top: 20px;
    @include screen(md) {
      width: calc(100% + 40px);
      margin-left: -20px;
      margin-right: -20px;
      margin-bottom: -11px;
      margin-top: 10px;
    }

    .dropdown-input {
      color: #000;
    }
  }
  .page-container {
    margin: auto;
    max-width: none;
    width: 100%;
    @include screen(md) {
      margin: 0 auto;
      max-width: 1280px;
    }
  }
  background-color: $grey;
  min-height: 60px;
  margin-right: -20px;
  margin-left: -20px;
  @include screen(md) {
    margin-right: 0px;
    margin-left: 0px;
  }

  .list-filters-content {
    padding-bottom: 11px;
    flex-direction: column;
    flex-wrap: wrap;
    @include screen(md) {
      flex-direction: row;
    }
  } 

  .dropdown {
    z-index: 11;
  }

  .dropdown__wrapper-dateAdopt {
    padding: 24px;
  }

  .button {
    color: $white;
    border-radius: 0;
    @include typography(p2);
    padding: 3.85px 25px;
    @include theme(map-get($treaties, name)) {
      background-color: map-get($treaties, m-color);
    }
    @include theme(map-get($customary, name)) {
      background-color: map-get($customary, m-color);
    }
    @include theme(map-get($national, name)) {
      background-color: map-get($national, m-color);
    }
  }
}
