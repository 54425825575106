.documents-table {
    font-size: 14px;
    .documents-table_header {
        font-size: 12px;
        background-color: whitesmoke;
        padding: 10px;
        [data-active] {
            cursor:pointer
        }
        [data-active='true'] {
            color: blue
        }
    }

    .documents-table_footer {
        text-align: center;
        > div {
            margin: 10px;
        }
    }

    .documents-table_row {
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid whitesmoke;
        .custom-button-remove {
            background-color: #E56161;
            padding-left: 0px;
            padding-right: 0px;
            margin-left: 5px;
        }
    }
    .flex {
        .col-06 {
            width: 6%;
        }
        .col-10 {
            width: 10%;
        }
        .col-12 {
            width: 12%;
        }
        .col {
            flex: 1;
        }
    }
    .text-center {
        text-align: center;
    }
    .documents-table__document-title {
        word-break: break-word;
    }
}
