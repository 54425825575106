@import '../../../../../styles/includes.scss';

.sources-detail {
  margin-left: 0px;

  @include screen(md) {
    margin-left: 20px;
  }
}

.sources-detail-title,
.ShortName {
  font-size: 20px;
  font-weight: bold;
  padding-top: 5px;
}

.sources-section-title h3 {
  font-size: 24px;
}

.ShortName {
  padding-bottom: 15px;
}

.cihl-link {
  font-size: 0;
  line-height: 0;
  color: transparent;
  margin: 0 10px;

  &:before {
    content: '';
    width: 15px;
    height: 15px;
    display: inline-block;
    background-image: url('../../../../../assets/icons/arrow-up-link.svg');
    background-size: cover;
    background-position: center;
    margin-bottom: -2px;
  }
}

.sources-detail-country {
  .sources-detail-title {
    padding-bottom: 10px;
    padding-top: 30px;
  }

  .Normal6pt {
    padding: 8px 0;
    word-break: break-all;
  }

  .ShortName {
    font-size: 16px;
  }
}

.sources-detail-default {
  .sources-detail-title {
    padding-bottom: 10px;
    padding-top: 215px;
    margin-top: -210px;
  }

  table {
    border: 0;
    font-size: 14px;
    margin-bottom: 20px;
    width: 100%;
    tr {
      border-bottom: 1px solid #EFEFEF;
    }
    th {
      text-align: left;
      background-color: #EFEFEF;
    }
    th, td {
      border: 0;
      padding: 10px;
    }
  }
}

.FullName {
  font-size: 14px;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  margin-bottom: 15px;
  word-break: break-word;
  text-align: justify;
  text-justify: inter-word;
}

.sources-detail-filter {
  background-color: #F8F8F8;
  padding: 20px;
  margin-bottom: 20px;

  .input,
  .react-select-container {
    margin-top: 10px;
    margin-right: 15px;
    border-radius: 0;
    background-color: #fff;
    width: 360px;
  }

  .sources-select-country {
    max-width: 100%;

    @include screen(md) {
      max-width: 240px;
    }
    
    .select__control {
      border-radius: 5px;
      padding: 5px 10px;
      box-shadow: none;
      border-color: #DBDBDB;

      &:hover {
        border-color: #DBDBDB;
      }
    }

    .select__indicators {
      span {
        display: none;
      }

      .select__indicator {
        width: 16px;

        svg {
          fill: #62A3BB;
        }
      }
    }
  }

  .react-select-container {
    width: 100%;
    min-width: 240px;
  }

  span {
    font-size: 14px;
    font-weight: bold;
    display: block;
  }

  .sources-detail-filter-letters {
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    overflow: auto;
    column-gap: 10px;
    text-transform: uppercase;
    color: #62A3BB;

    @include screen(md) {
      column-gap: 1px;
    }

    div {
      font-size: 14px;
      line-height: 28px;
      font-family: 'Open Sans';
      color: #62A3BB;
      padding: 5px;
      margin: 10px 0 5px;
      text-transform: uppercase;
      cursor: pointer;

      &.selected {
        background-color: #d4d4d4;
      }

      @include screen(md) {
        font-size: 12px;
      }
    }
  }
}

.sources-detail-content {
  .green {
    color: #088a08;
    word-break: break-all;
  }
}

@media screen and (max-width: 760px) {
  .sources-detail-filter {
    display: block;
    margin-left: -20px;
    width: calc(100% + 40px);
    max-width: calc(100% + 40px);
    padding: 0 20px;

    label {
      margin-bottom: 20px;
      display: block;
    }

    .input {
      margin-right: 0;
      margin-bottom: 20px;
      max-width: 100%;
    }
  }

  .sources-detail-filter-letters-wrap {
      margin-top: 20px;
  }

  .sources-detail-default {
    table {
      tr {
        display: flex;
        flex-wrap: wrap;

        th {
          &:nth-child(2) {
            flex-grow: 2;
          }
          &:nth-child(3) {
            order: 2;
          }
          &:nth-child(4) {
            order: 1;
          }
        }

        td {
          &:nth-child(3) {
            order: 1;
            width: 100%;
          }    
        }      
      }
    }
  }
}

.customary-sources__details-filter {
  background-color: #F8F8F8;
  font-family: "Open Sans";
  font-size: 12px;
  line-height: 28px;
  display: none;

  @include screen(md) {
    display: flex;
  }

  &.active {
    display: flex;
    flex-direction: column;
    padding: 20px 24px 40px;
    margin-left: -24px;
    width: calc(100% + 48px);
    margin-bottom: 10px;

    .customary-practice__filter-letters {
      flex-wrap: wrap;
    }
  }

  .input {
    border-radius: 5px;
    padding: 5px 10px;
  }

  .style_value__9xsze {
    border-radius: 5px;
    padding: 5px 10px;
    height: 48px;

    @include screen(md) {
      min-width: 260px;
    }
  }

  label {
    display: inline-block;
  }

  .custom-button {
    margin-top: 0px;

    @include screen(md) {
      margin-top: 40px;
    }
  }
  .sources-detail-filter-letters-wrap {
    @include screen(md) {
      margin-left: 90px;
    }
  }
}

.sources-detail-default {
  margin-left: 0;
  
  .ShortName {
    font-size: 16px;
    line-height: 28px;
  }
  .FullName {
    border-bottom: none;
  }
}
